import { ReportsMessages } from '../types';

export const reportsDE: ReportsMessages = {
    'app.reports.insuficient_data': 'Es gibt nicht genügend Daten, um den Bericht zu erstellen. ',
    'app.reports.decile': 'Dezil',
    'app.reports.group_report.title': 'Gruppenbericht',
    'app.reports.longitudinal.title': 'Längsschnittbericht',
    'app.reports.comparative_by_group.title': 'Zwischen Gruppenbericht',
    'app.reports.comparative_by_individual.title': 'Innerhalb des Gruppenberichts',
    'app.report.menu.introduction': 'Informationsbericht',
    'app.report.menu.basic_descriptive_statistics': 'Background Domain',
    'app.report.menu.aggregate_profile': 'Summe Profil',
    'app.report.menu.decile_profile': 'Dezil-Profil',
    'app.report.menu.aggregate_by_demographics': 'Summe nach demografischen Merkmalen',
    'app.report.menu.background_domain_contrast': 'Background Domain Kontrast',
    'app.report.menu.profile_background_contrast': 'Background Domain Kontrast',
    'app.report.menu.profile_contrast': 'Profil Kontrast',
    'app.report.menu.experiential_reflection_items': 'Erfahrungswerte zur Reflexion',
    'app.report.menu.country_of_origin': 'Herkunftsland',
    'app.report.menu.gender': 'Geschlecht',
    'app.report.menu.education': 'Ausbildung',
    'app.report.menu.ethnicity': 'Ethnie',
    'app.report.menu.income': 'Einkommen',
    'app.report.menu.interest': 'Interessen',
    'app.report.menu.politics': 'Politik',
    'app.report.menu.religion': 'Religion',
    'app.report.menu.satisfaction': 'Befriedigung',
    'app.report.menu.question_1': 'Frage 1',
    'app.report.menu.question_2': 'Frage 2',
    'app.report.menu.question_3': 'Frage 3',
    'app.report.introduction.create_date': 'Erstellt am:',
    'app.report.introduction.created_by': 'Benutzer:in:',
    'app.report.introduction.program_name': 'Programm oder Erfahrung:',
    'app.report.introduction.group_name': 'BEVI- Administration(en):',
    'app.report.introduction.number_of_participants': 'Anzahl Teilnehmer:innen:',
    'app.report.introduction.visualized_by': 'Gesehen von:',
    'app.report.scales.consistency': 'Konsistenz',
    'app.report.scales.congruency': 'Kongruenz',
    'app.report.scales.negative_life_events': 'Negative Lebensereignisse',
    'app.report.scales.needs_closure': 'Braucht Abschluss',
    'app.report.scales.needs_fulfillment': 'Bedürfniserfüllung',
    'app.report.scales.identity_diffusion': 'Identitätsdiffusion',
    'app.report.scales.basic_openness': 'Grundsätzliche Offenheit',
    'app.report.scales.self_certitude': 'Gewissheit über sich selbst',
    'app.report.scales.basic_determinism': 'Grundsätzlicher Determinismus',
    'app.report.scales.socioemotional_convergence': 'Sozioemotionale Konvergenz',
    'app.report.scales.physical_resonance': 'Physische Resonanz',
    'app.report.scales.emotional_attunement': 'Emotionaler Einklang',
    'app.report.scales.self_awareness': 'Selbstbewusstsein',
    'app.report.scales.meaning_quest': 'Suche nach dem Sinn',
    'app.report.scales.religious_traditionalism': 'Religiöser Traditionalismus',
    'app.report.scales.gender_traditionalism': 'Geschlechterbezogener Traditionalismus',
    'app.report.scales.sociocultural_openness': 'Soziokulturelle Offenheit',
    'app.report.scales.ecological_resonance': 'Ökologische Resonanz',
    'app.report.scales.global_resonance': 'Globale Resonanz',
    'app.report.aggregate_profile.high': 'Hoch',
    'app.report.aggregate_profile.low': 'Niedrig',
    'app.report.aggregate_profile.average': 'Durchschnittlich',
    'app.report.background_statistic_item.income_participants': '{0} von {1} Teilnehmer:innen - {2} %',
    'app.report.background_statistic_item.background_information': 'Hintergrundinformationen',
    'app.report.background_statistic_item.lowest': 'Niedrigste',
    'app.report.background_statistic_item.middle': 'Mittlere',
    'app.report.background_statistic_item.highest': 'Höchste',
    'app.report.background_statistic_item.age': 'Alter',
    'app.report.background_statistic_item.average_age': 'Durchschnittsalter:',
    'app.report.background_statistic_item.answer': 'Antwort:',
    'app.report.background_statistic_item.not_answer': 'Nicht antworten:',
    'app.report.background_statistic_item.range': 'Bereich:',
    'app.report.background_statistic_item.gender': 'Geschlecht',
    'app.report.background_statistic_item.ethnic_background': 'Ethnische Herkunft',
    'app.report.background_statistic_item.country_of_origin': 'Herkunftsland',
    'app.report.background_statistic_item.raised_in': 'Aufgewachsen in',
    'app.report.background_statistic_item.not_raised_in': 'Nicht aufgewachsen in',
    'app.report.background_statistic_item.details': 'Details',
    'app.report.background_statistic_item.years_of_education': 'Ausbildung in Jahren',
    'app.report.background_statistic_item.income': 'Einkommen',
    'app.report.background_statistic_item.average': 'Durchschnitt:',
    'app.report.background_statistic_item.marital_status': 'Personenstand',
    'app.report.background_statistic_item.religion': 'Religion',
    'app.report.background_statistic_item.interest': 'Interessen',
    'app.report.background_statistic_item.satisfaction': 'Befriedigung',
    'app.report.background_statistic_item.domain_scores': 'Bewertung',
    'app.report.background_statistic_item.validity': 'I. Gültigkeit',
    'app.report.background_statistic_item.average_missing_items': 'Durchschnittlich Fehlende Punkte',
    'app.report.background_statistic_item.average_quantitative_completion_time': 'Durchschnittliche quantitative Ausfüllzeit',
    'app.report.background_statistic_item.full_scale': 'II. Voller Umfang',
    'app.report.background_statistic_item.formative_variables': 'III. Formative Variablen',
    'app.report.background_statistic_item.fulfillment_of_core_needs': 'IV. Erfüllung der Kernbedürfnisse',
    'app.report.background_statistic_item.tolerance_of_disequilibrium': 'V. Toleranz gegenüber Unausgeglichenheit',
    'app.report.background_statistic_item.critical_thinking': 'VI. Kritisches Denken',
    'app.report.background_statistic_item.self_access': 'VII. Zugang zu sich selbst',
    'app.report.background_statistic_item.other_access': 'VIII. Zugang zu anderen',
    'app.report.background_statistic_item.global_access': 'IX: Globaler Zugang',
    'app.report.decile_profile.high': 'Hoch',
    'app.report.decile_profile.low': 'Niedrig',
    'app.report.decile_profile.average': 'Durchschnittlich',
    'app.report.background_domain_contrast.title': 'Hintergrund Bereich Kontrast',
    'app.report.background_domain_contrast.lowest_full_scale': 'Niedrigster Skalenenwert',
    'app.report.background_domain_contrast.middle_full_scale': 'Mittlerer Skalenenwert',
    'app.report.background_domain_contrast.highest_full_scale': 'Höchster Skalenenwert',
    'app.report.background_domain_contrast.mean': 'Bedeuten',
    'app.report.background_domain_contrast.range': 'Bereich',
    'app.report.background_domain_contrast.background_information': 'Background Information',
    'app.report.profile_contrast.lowest': 'Niedrigste',
    'app.report.profile_contrast.middle': 'Mittel',
    'app.report.profile_contrast.highest': 'Höchste',
    'app.report.profile_contrast.lowest_optimal_profiles': 'Niedrigste optimale Profile',
    'app.report.profile_contrast.middle_optimal_profiles': 'Mittlere optimale Profile',
    'app.report.profile_contrast.highest_optimal_profiles': 'Höchste optimale Profile',
    'app.report.aggregate_profile.country_of_origin.selected_country': 'Ausgewähltes Land',
    'app.report.aggregate_profile.country_of_origin.selected_countries': 'Ausgewählte Länder',
    'app.report.aggregate_profile.religious_orientation.selected_ethnic': 'Ausgewählte Ethnie',
    'app.report.aggregate_profile.religious_orientation.selected_ethnics': 'Ausgewählte Ethnien',
    'app.report.aggregate_profile.country_of_origin.other': 'Andere',
    'app.report.aggregate_profile.religious_orientation.other': 'Andere',
    'app.report.aggregate_profile.parents_country.same': 'Gleich',
    'app.report.aggregate_profile.parents_country.different': 'Anders',
    'app.report.aggregate_profile.relationship_status.selected_relationship_status': 'Ausgewählter Beziehungsstatus',
    'app.report.aggregate_profile.religion.selected_religion': 'Ausgewählte Religion',
    'app.report.aggregate_profile.relationship_status.other': 'Andere',
    'app.report.aggregate_profile.gender.male': 'Männlich',
    'app.report.aggregate_profile.gender.female': 'Weiblich',
    'app.report.aggregate_profile.gender.self_identify': 'Selbstidentifizierung',
    'app.report.aggregate_profile.education.lowest_30': 'Niedrigste 30 %',
    'app.report.aggregate_profile.education.highest_30': 'Höchste 30 %',
    'app.report.aggregate_profile.ethnicity.selected_ethnicity': 'Ausgewählte Ethnie',
    'app.report.aggregate_profile.ethnicity.selected_ethnicities': 'Ausgewählte Ethnien',
    'app.report.aggregate_profile.ethnicity.other': 'Andere',
    'app.report.aggregate_profile.income.bottom_third': 'Unteres Drittel',
    'app.report.aggregate_profile.income.middle_third': 'Mittleres Drittel',
    'app.report.aggregate_profile.income.top_third': 'Oberes Drittel',
    'app.report.aggregate_profile.interest.low': 'Niedrig',
    'app.report.aggregate_profile.interest.middle': 'Mittel',
    'app.report.aggregate_profile.interest.high': 'Hoch',
    'app.report.aggregate_profile.politics.low': 'Niedrig',
    'app.report.aggregate_profile.politics.middle': 'Mittel',
    'app.report.aggregate_profile.politics.high': 'Hoch',
    'app.report.aggregate_profile.age.low': 'Niedrig',
    'app.report.aggregate_profile.age.middle': 'Mittel',
    'app.report.aggregate_profile.age.high': 'Hoch',
    'app.report.aggregate_profile.religion.low': 'Niedrig',
    'app.report.aggregate_profile.religion.middle': 'Mittel',
    'app.report.aggregate_profile.religion.high': 'Hoch',
    'app.report.aggregate_profile.satisfaction.low': 'Niedrig',
    'app.report.aggregate_profile.satisfaction.middle': 'Mittel',
    'app.report.aggregate_profile.satisfaction.high': 'Hoch',
    'app.report.aggregate_profile.mother_father_education.low': 'Niedrig',
    'app.report.aggregate_profile.mother_father_education.middle': 'Mittel',
    'app.report.aggregate_profile.mother_father_education.high': 'Hoch',
    'app.report.aggregate_profile.time_spent_other_countries.low': 'Niedrig',
    'app.report.aggregate_profile.time_spent_other_countries.middle': 'Mittel',
    'app.report.aggregate_profile.time_spent_other_countries.high': 'Hoch',
    'app.report.aggregate_profile.minority_group.minority': 'Minderheit',
    'app.report.aggregate_profile.minority_group.majority': 'Mehrheit',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work': 'Ausgewählter primärer Arbeitsbereich',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work': 'Ausgewählte primäre Arbeitsbereiche',
    'app.report.aggregate_profile.primary_area_of_work.other': 'Andere',
    'app.report.aggregate_profile.years_of_language_study.low': 'Niedrig',
    'app.report.aggregate_profile.years_of_language_study.middle': 'Mittel',
    'app.report.aggregate_profile.years_of_language_study.high': 'Hoch',
    'app.report.aggregate_profile.academic_rank.low': 'Niedrig',
    'app.report.aggregate_profile.academic_rank.middle': 'Mittel',
    'app.report.aggregate_profile.academic_rank.high': 'Hoch',
    'app.report.aggregate_profile.visited_countries.low': 'Niedrig',
    'app.report.aggregate_profile.visited_countries.middle': 'Mittel',
    'app.report.aggregate_profile.visited_countries.high': 'Hoch',
    'app.report.aggregate_profile.fluency.low': 'Nicht oder Minimum',
    'app.report.aggregate_profile.fluency.middle': 'Einigermaßen oder mäßig',
    'app.report.aggregate_profile.fluency.high': 'Größtenteils fließend oder fließend',
    //Without translation
    'app.report.menu.narrative_report': 'Narrative Report',
    'app.report.aggregate_demographic.core_demographics': 'Core Demographics',
    'app.report.aggregate_demographic.parental_background': 'Parental Background',
    'app.report.aggregate_demographic.political_religious_inclinations': 'Political and Religious Inclinations',
    'app.report.aggregate_demographic.education_background': 'Education Background',
    'app.report.aggregate_demographic.international_cultural_experiences': 'International and Cultural Experiences',
    'app.report.aggregate_demographic.age': 'Age',
    'app.report.aggregate_demographic.gender': 'Gender',
    'app.report.aggregate_demographic.relationship_status': 'Relationship Status',
    'app.report.aggregate_demographic.country_origin': 'Country of Origin',
    'app.report.aggregate_demographic.parent_country': 'Parent\'s Country',
    'app.report.aggregate_demographic.mother_education': 'Mother\'s Education',
    'app.report.aggregate_demographic.father_education': 'Father\'s Education',
    'app.report.aggregate_demographic.ethnic_background': 'Ethnic Background',
    'app.report.aggregate_demographic.minority_group': 'Minority Group',
    'app.report.aggregate_demographic.political_orientation': 'Political Orientation',
    'app.report.aggregate_demographic.religious_orientation': 'Religious Orientation',
    'app.report.aggregate_demographic.religious_commitment': 'Religious Commitment',
    'app.report.aggregate_demographic.income_level': 'Income Level',
    'app.report.aggregate_demographic.years_education': 'Years of Education',
    'app.report.aggregate_demographic.primary_area_work': 'Primary area or work',
    'app.report.aggregate_demographic.academic_rank': 'Academic Rank',
    'app.report.aggregate_demographic.time_spent_countries': 'Time spent in other countries',
    'app.report.aggregate_demographic.visited_countries': 'Visited Countries',
    'app.report.aggregate_demographic.personal_interest': 'Personal interest',
    'app.report.aggregate_demographic.study_abroad_satisfaction': 'Study abroad satisfaction',
    'app.report.aggregate_demographic.fluency': 'Fluency',
    'app.report.aggregate_demographic.years_language_study': 'Years of Language Study',
    'app.report.comparative_individual_report_result_title': 'Ergebnisse des individuellen BEVI-Änderungsberichts',
    'app.report.comparative_individual_report_result_description': 'Von einer Verwaltung zur nächsten bewegen Sie sich zwischen verschiedenen „Veränderungsbändern“ auf dem BEVI (d. h. Sie können von einer Verwaltung zur nächsten erhebliche Veränderungen verzeichnen). „Being Bevi“ kann Ihnen mehr über die Veränderungen erzählen, die tatsächlich für Sie zwischen verschiedenen BEVI-Verwaltungen stattfinden (d. h. Being Bevi ist die KI-Einheit, die im Beviverse lebt – klicken Sie auf den Kreis in der unteren rechten Ecke). Wenn unten farbiger Text angezeigt wird, bedeutet dies normalerweise, dass sich Ihre Ergebnisse von einer BEVI-Verabreichung zur nächsten erheblich geändert haben. Der farbige Text verweist auf die Richtung(en), in der bzw. in denen diese Änderung bei verschiedenen Verabreichungen des BEVI stattgefunden hat (z. B. normalerweise vom ersten Mal bis zum zweiten Mal, aber das hängt wirklich davon ab, wie oft Sie das BEVI eingenommen haben). den BEVI durchgeführt haben und welche Vergleiche Sie durchführen). Wenn Sie tiefer in die Tiefe gehen und spezifische Änderungen auf den 17 Skalen des BEVI sehen möchten, wenden Sie sich bitte entweder an Ihren BEVI-Administrator oder nehmen Sie Kontakt mit dem BEVI-Team unter auf',
    'app.report.comparative_individual_report_result_description_no_change': 'Von einer Regierung zur nächsten bleiben Sie im gleichen „Band“ der Veränderungen. „Being Bevi“ kann Ihnen mehr über die Veränderungen erzählen, die tatsächlich für Sie zwischen einer BEVI-Administration zur nächsten stattfinden (d. h. Being Bevi ist die KI-Entität, die im Beviverse lebt – klicken Sie auf den Kreis in der unteren rechten Ecke). ). Wenn Sie tiefer in die Tiefe gehen und spezifische Änderungen auf den 17 Skalen des BEVI sehen möchten, wenden Sie sich bitte entweder an Ihren BEVI-Administrator oder nehmen Sie Kontakt mit dem BEVI-Team unter auf',
    'app.report.partner_report_result_title': 'Ergebnisse des BEVI-Partnerberichts',
    'app.report.partner_change_report_result_title': 'Ergebnisse des BEVI-Partneränderungsberichts',
    'app.report.permission.modal_title': 'Liebe/r BEVI-Teilnehmer/in',
    'app.report.permission.modal_description': `
    <h5>Sie haben der Durchführung des BEVI bereits zugestimmt, aber bevor wir mit Ihnen an Ihrem BEVI-Bericht arbeiten können, bitten wir Sie um Ihr Einverständnis</h5>
    <br />
    <p>
    1) dass Sie an einer oder mehreren Sitzungen teilnehmen möchten, in denen Ihr individueller Bericht mit Ihnen besprochen wird und
    </p>
    <p>
    2) dass Sie <b>[#USERS#]</b> ermächtigen, Ihren individuellen Bericht (z. B. Ihre Beschreibung, Ergebnisse) zu überprüfen und die Bedeutung oder Auswirkungen Ihres BEVI-Berichts mit Ihnen zu besprechen.
    Selbstverständlich bleiben Ihre individuellen Ergebnisse, wie in der Einwilligungserklärung für die BEVI angegeben, absolut vertraulich. Wenn Sie Fragen haben oder weitere Informationen benötigen, wenden Sie sich bitte an den BEVI-Administrator, der mit Ihnen zusammenarbeitet.
    </p>
    <p>
    Wenn Sie die beiden oben genannten Bestimmungen verstanden haben und ihnen zustimmen, klicken Sie bitte unten auf die Schaltfläche „Ich stimme zu“.
    </p>
    <center>Vielen Dank für Ihr Interesse am BEVI!</center>
    `,
    'app.report.permission.pending': 'Ausstehend',
    'app.report.permission.approved': 'Genehmigt',
    'app.report.permission.rejected': 'Abgelehnt',
    'app.report.permission.approve': 'IICH STIMME ZU',
    'app.report.permission.reject': 'ICH STIMME NICHT ZU',
    "app.reports.selectAll": "Alle auswählen",
    "app.reports.deselectAll": "Alle abwählen"
}
