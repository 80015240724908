import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppProps, appLogic } from "../redux/appLogic";
import { HasPermissionCapability, IsSuperAdmin } from "../utils/userHelper";
import { UserPermissions } from "../services/usersService";
import { useValues } from 'kea';
import { LocalStorage_BeviLanguage } from "../IntlProviderWrapper";
import { isEmpty, isNil } from "lodash";

type Props = {
  id?: any,
  type: string,
  formIds?: string[],
}

// const Container = styled.div`
//     #being-bevi > div > div:not(.text-xs) {
//         width: 400px;
//     }

//     #being-bevi > div > div > div {
//         z-index: 50;
//     }
//     #being-bevi > div {
//         width: auto;
//     }
// `;

const Container = styled.div`
    #being-bevi > div > div > div {
        z-index: 50;
    }

    #being-bevi > div {
      height: initial !important;
    }
`;

const FORM_IDS_SAMPLE_REPORT = [213577, 216400, 216399, 216387, 216431, 216387, 213577, 213578];
const REPORT_IDS = [3443, 3444, 3433, 3434, 3436, 3446, 3435, 3445];

export const BeingBevi = ({ id, type, formIds }: Props) => {
  const [beingBeviRunning, setBeingBeviRunning] = useState<boolean>(false);
  const { tokenInfo, token }: AppProps = useValues(appLogic);
  const locale = localStorage.getItem(LocalStorage_BeviLanguage);
  let isSampleReport = false;

  if (formIds && formIds.length > 0) {
    for (let index = 0; index < formIds.length; index++) {
      const element = formIds[index];
      if (FORM_IDS_SAMPLE_REPORT.includes(parseFloat(element))) {
        isSampleReport = true;
        break;
      }
    }
  } else if ((!isNil(id) && !isEmpty(id))) {
    try {
      isSampleReport = REPORT_IDS.includes(parseFloat(id));
    } catch (error) {
      console.log('error', error)
    }
  }


  useEffect(() => {
    if (document.getElementById('being-bevi') && (id || formIds) &&
      token && !beingBeviRunning && (isSampleReport === true || IsSuperAdmin(tokenInfo) || HasPermissionCapability(tokenInfo, UserPermissions.canVisualizeChatBot))) {
      const reportId = (!isNil(id) && !isEmpty(id)) ? id : formIds && formIds.length > 0 ? formIds?.join(',') : '';
      setBeingBeviRunning(true);
      console.log('-------------', !isNil(id), !isEmpty(id));
      console.log({
        url: "https://dev--bev-chat.netlify.app/",
        reportId: reportId,
        reportType: type,
        isSampleReport: isSampleReport,
        token: token,
        demo: HasPermissionCapability(tokenInfo, UserPermissions.canTalkToChatBot),
        hideScores: !HasPermissionCapability(tokenInfo, UserPermissions.canVisualizeProfileIndividualReport),
        locale,
      });
      console.log('-------------');
      (window as any).BeingBevi.init(document.getElementById('being-bevi'), {
        url: "https://dev--bev-chat.netlify.app/",
        reportId: reportId,
        reportType: type,
        token: token,
        isSampleReport: isSampleReport,
        demo: HasPermissionCapability(tokenInfo, UserPermissions.canTalkToChatBot),
        hideScores: !HasPermissionCapability(tokenInfo, UserPermissions.canVisualizeProfileIndividualReport),
        locale,
      });
    }

  }, [id, token, type, beingBeviRunning, tokenInfo, locale]);

  return <Container>
    <div id='being-bevi' />
  </Container>
}
