import {
    DemographicPageMessages, DemographicQuestionsMessages, DemographicResponseOptionMessages
} from '../types';

const demographicQuestions: DemographicQuestionsMessages = {
    'app.demographic.age': 'उम्र',
    'app.demographic.gender': 'लिंग',
    'app.demographic.marital_status': 'रिश्ते की स्थिति',
    'app.demographic.level_of_fluency': 'कृपया मूल भाषाओं (आपके घर और/या समुदाय में बोली जाने वाली भाषा, और/या प्राथमिक/व्याकरण/प्राथमिक विद्यालय(स्कूलों) में शिक्षा की भाषा) के अलावा किसी अन्य भाषा में आपके उच्चतम स्तर के प्रवाह का संकेत दें?.',
    'app.demographic.education_years': `कृपया बताएं कि आपने कितने वर्षों की औपचारिक शिक्षा पूरी कर ली है।`,
    'app.demographic.raised_in_country_where_currently_in': 'पहली कक्षा में प्राथमिक शिक्षा से शुरुआत (उदाहरण के लिए, प्रीस्कूल या किंडरगार्टन से आगे), आपने कितने वर्षों की औपचारिक शिक्षा पूरी कर ली है:',
    'app.demographic.ethnic': 'आपका जातीय बैकग्राउंड क्या है? ',
    'app.demographic.ethnic_other': 'कृपया दिए गए स्थान में वर्णन करें:',
    'app.demographic.ethnic_asian': `एशियाई। उदाहरण के लिए, कंबोडिया, चीन, भारत, जापान, कोरिया, मलेशिया, पाकिस्तान, फिलीपीन द्वीप, थाईलैंड और वियतनाम सहित सुदूर पूर्व, भारतीय उपमहाद्वीप या दक्षिण पूर्व एशिया के देशों या क्षेत्रों में उत्पन्न होने वाला व्यक्ति।`,
    'app.demographic.ethnic_african': 'काला या अफ़्रीकी। अफ़्रीका के किसी काले नस्लीय समूह में उत्पन्न होने वाला व्यक्ति।',
    'app.demographic.ethnic_latino': `हिस्पैनिक या लैटिनो। उदाहरण के लिए, क्यूबा, ​​मैक्सिको, प्यूर्टो रिको, दक्षिण या मध्य अमेरिकी, या अन्य स्पेनिश संस्कृति या मूल सहित देशों या क्षेत्रों में उत्पन्न होने वाला व्यक्ति, नस्ल की परवाह किए बिना।`,
    'app.demographic.ethnic_indigenous': `स्वदेशी। किसी दिए गए देश या क्षेत्र के मूल लोगों में से किसी में उत्पन्न होने वाला व्यक्ति जो किसी स्वदेशी जनजाति या समुदाय के साथ संबद्धता या लगाव रखता है।`,
    'app.demographic.ethnic_middle_eastern': `मध्य पूर्वी। ऐसा व्यक्ति जिसकी उत्पत्ति अफगानिस्तान से लेकर लीबिया तक किसी भी देश या क्षेत्र में हुई हो, उदाहरण के लिए मिस्र, ईरान, इराक, इज़राइल, जॉर्डन, लेबनान, सऊदी अरब, सूडान, सीरिया, तुर्की या अरब प्रायद्वीप के देश।`,
    'app.demographic.ethnic_white': 'सफ़ेद या कोकेशियान. यूरोप के किसी श्वेत नस्लीय समूह में उत्पन्न होने वाला व्यक्ति।',
    'app.demographic.completing_bevi': 'कृपया बताएं कि आप BEVI कब पूरा कर रहे हैं।',
    'app.demographic.completing_bevi_other': 'कृपया नीचे उन परिस्थितियों का वर्णन करें जिनके तहत आप BEVI पूरा कर रहे हैं।',
    'app.demographic.previously_completed': 'क्या आपने पहले BEVI पूरा कर लिया है?',
    'app.demographic.disadvantaged_or_minority_group': 'आपके अनुभव में, क्या आप किसी वंचित या असहाय समूह के सदस्य हैं?',
    'app.demographic.disadvantaged_or_minority_group_yes': 'कृपया दिए गए स्थान में कोई अतिरिक्त जानकारी प्रदान करें।',
    'app.demographic.primary_area_of_work': 'नीचे दिए गए विकल्पों में से जो आपके अध्ययन के क्षेत्र या आपके प्राथमिक क्षेत्र के काम/व्यवसायिक रूचि के सबसे निकट सन्दर्भ है, उसका चयन कीजिये।',
    'app.demographic.current_area': 'कृपया दिए गए स्थान में आपके वर्तमान अध्ययन क्षेत्र या व्यवसायिक क्षेत्र की सूची दर्ज करें:',
    'app.demographic.political_orientation': 'कृपा नीचे दिए गए स्लाइडर का उपयोग करके अपने राजनीति रूझान को "रूढ़िवादी/पारंपरिक (जैसे मौजुदा कानून, सिस्टम और अस्तित्व के तरीकों को बनाएं रखने या सुरक्षित करने के पक्ष में)" से " उदारवादी/प्रगतिशील (जैसे, मौजुदा कानून, सिस्टम और अस्तित्व को बदलने या सुधारने के पक्ष में)" अनुकरण करें।',
    'app.demographic.political_commitment': 'कृपया बताएं कि आप अपने राजनीतिक विचारों के प्रति कितने समर्पित हैं।',
    'app.demographic.political_orientation_conservative_label': 'रूढ़िवादी/पारंपरिक',
    'app.demographic.political_orientation_liberal_label': 'उदार/प्रगतिशील',
    'app.demographic.religion': 'निम्नलिखित में से कौनसा विकल्प आपकी वर्तमान धार्मिक या आध्यात्मिक मान्यताओं में से सबसे अधिक मेल खाता है??',
    'app.demographic.religion_other': 'कृपया दिए गए स्थान में वर्णन करें:',
    'app.demographic.religion_scale': 'कृपया बताएँ कि आप धर्म या आध्यात्मिकता के प्रति कितने समर्पित हैं',
    'app.demographic.religion_scale_specified': 'कृपया {0} के प्रति अपनी व्यक्तिगत प्रतिबद्धता का स्तर बताएं',
    'app.demographic.income_level': 'कृपया नीचे दिए गए स्लाइडर का उपयोग करें और अपनी आय या संसाधन प्राप्ति को अपने समाज में दूसरों की तुलना में "सबसे कम" से "सबसे अधिक" तक दर्शाएं।',
    'app.demographic.income_less_label': 'सबसे कम',
    'app.demographic.income_more_label': 'सबसे अधिक',
    'app.demographic.academic_rank_lowest_label': 'सबसे कम',
    'app.demographic.academic_rank_highest_label': 'सबसे अधिक',
    'app.demographic.academic_rank': 'आपके सबसे हाल के स्कूल अनुभव में आपके साथ पढ़ने वाले अन्य छात्रों के मुकाबले, कृपया अपनी शैक्षणिक स्थिति या रैंक का सर्वोत्तम अनुमान प्रदान करें (उदाहरण के लिए, "सबसे कम" से "सबसे अधिक" तक) ।',
    'app.demographic.primary_country': 'आपका जन्म देश कौन सा है?',
    'app.demographic.time_in_other_countries': 'नीचे दिए गए विकल्पों में से, कृपया बताएं कि आपने अपने मूल देश के अलावा अन्य देशों/क्षेत्रों में कितना समय बिताया है।',
    'app.demographic.time_in_other_countries_other': 'कृपया उल्लिखित करें',
    'app.demographic.visit_countries': 'आप अपने मूल देश के अलावा कितने देशों/क्षेत्रों में गए या रहे हैं?',
    'app.demographic.country_explanation_field': 'अपना देश ढूंढने के लिए, आप या तो ड्रॉपडाउन मेनू से देश का चयन कर सकते हैं या टाइप करके भाषा का चयन कर सकते हैं।',
    'app.demographic.country_mother_born': 'कृपया वह देश/क्षेत्र बताएं जहां आपकी मां का जन्म हुआ था:',
    'app.demographic.country_father_born': 'कृपया वह देश/क्षेत्र बताएं जहां आपके पिता का जन्म हुआ था:',
    'app.demographic.personal_interest': 'कृपया अंतरराष्ट्रीय, बहुसांस्कृतिक, या अंतरसांस्कृतिक अनुभवों में अपने व्यक्तिगत रूचि स्तर को इंगित करें (उदाहरण के लिए, विदेश में काम, अध्ययन या सेवा; वर्चुअल एक्सचेंज (VE) या कोलेबरेटिव ऑनलाइन इंटरनेशनल लर्निंग (COIL); बहुसांस्कृतिक पाठ्यक्रम; विविध टीमों में भागीदारी)',
    'app.demographic.participated_experiences': 'क्या आपने पहले निम्नलिखित में से कोई अनुभव पूरा कर लिया है, या वर्तमान में पूरा कर रहे हैं? (जो लागू हो उस पर क्लिक करें)',
    'app.demographic.participated_learning_courses': 'क्या आपने पहले से कोई भी निम्नलिखित वैश्विक और विविधतापूर्ण शिक्षण पाठ्यक्रम/कार्यक्रम पूरे किये हैं, या वर्तमान में कोई पूरा कर रहे हैं? (जो भी लागू हों, उन्हें चुनें)',
    'app.demographic.plan_participate_experiences': 'क्या आप निम्नलिखित में से किसी अनुभव को पूरा करने की योजना बना रहे हैं? (जो लागू हो उस पर क्लिक करें)',
    'app.demographic.plan_participate_learning_courses': 'क्या आप निम्नलिखित में से किसी वैश्विक और विविधतापूर्ण शिक्षण पाठ्यक्रम/कार्यक्रम को पूरा करने की योजना बना रहे हैं? (जो लागू हो उस पर क्लिक करें)',
    'app.demographic.experience_satisfaction': 'यदि आपने अंतरराष्ट्रीय, बहुसांस्कृतिक या अंतरसांस्कृतिक अनुभवों में भाग लिया है, या वर्तमान में भाग ले रहे हैं (उदाहरण के लिए, विदेश में काम, अध्ययन, या सेवा; वर्चुअल एक्सचेंज (VE) या कोलेबरेटिव ऑनलाइन इंटरनेशनल लर्निंग (COIL); बहुसांस्कृतिक पाठ्यक्रम; विविध टीमों में भागीदारी), तो कृपया इन अनुभवों के साथ अपनी संतुष्टि का स्तर बताएं ।',
    'app.demographic.languages_speak': 'आप कितनी भाषाओँ को मूल भाषा के रूप में बोलते हैं? (आपके घर और/या समुदाय में बोली जाने वाली भाषा, और/या आपके प्राथमिक/व्याकरण/प्राथमिक विद्यालयों में शिक्षा की भाषा)',
    'app.demographic.years_studied_foreign_language': 'आपने कुल कितने वर्षों तक विदेशी भाषा/भाषाएँ पढ़ी हैं??',
    'app.demographic.primary_language': 'आपकी प्राथमिक भाषा क्या है (अर्थात, पहली या मुख्य भाषा जो आप बोलते हैं)? यदि आपके पास एक से अधिक प्राथमिक भाषा है तो आप एक से अधिक भाषा का चयन कर सकते हैं। अपनी भाषा ढूंढने के लिए, आप या तो ड्रॉपडाउन मेनू से भाषा(भाषाओं) का चयन कर सकते हैं या टाइप करके भाषा(भाषाओं) का चयन कर सकते हैं।',
    'app.demographic.primary_language_other': 'क्या आप अपनी मूल भाषा (अर्थात, अपनी पहली या प्राथमिक भाषा) के अलावा कोई अन्य भाषा बोलते हैं?',
    'app.demographic.language_level_of_fluency': 'नीचे दिए गए विकल्पों में से उन भाषाओँ का चयन करें जो बोलते हैं और अपनी भाषा पर पकड़ का स्तर भी बताएं।',
    'app.demographic.language_level_of_fluency_add_anwser': 'जोड़ें',
    'app.demographic.table_level_of_fluency': 'भाषा में पकड़ का स्तर',
    'app.demographic.table_language': 'भाषा',
    'app.demographic.table_action': 'क्रिया ',
    'app.demographic.remove_language_tooltip': 'भाषा हटाएँ',
    'app.demographic.father_formal_education': 'कृपया अपने पिता द्वारा प्राप्त की गई उच्चतम औपचारिक शिक्षा का स्तर बताएं:',
    'app.demographic.mother_formal_education': 'कृपया अपनी माँ द्वारा प्राप्त की गई उच्चतम औपचारिक शिक्षा का स्तर बताएं:',
    'app.demographic.comparative': 'तुलनात्मक व्यक्तिगत रिपोर्ट',
    'app.demographic.compare': 'तुलना',
    'app.demographic.select_multiple_reports': 'एक से अधिक रिपोर्ट चुनें',
}

const demographicResponseOptions: DemographicResponseOptionMessages = {
    'app.demographic.language_options.Afrikaans': 'अफ्रीकी',
    'app.demographic.language_options.Albanian': 'अल्बानियन',
    'app.demographic.language_options.Amharic': 'अम्हारिक्',
    'app.demographic.language_options.Arabic': 'अरबी',
    'app.demographic.language_options.Armenian': 'अर्मेनियाई',
    'app.demographic.language_options.Basque': 'बास्क',
    'app.demographic.language_options.Bengali': 'बांग्ला',
    'app.demographic.language_options.Byelorussian': 'बेलारूसी',
    'app.demographic.language_options.Burmese': 'बर्मी',
    'app.demographic.language_options.Bulgarian': 'बल्गेरियाई',
    'app.demographic.language_options.Catalan': 'कैटलन',
    'app.demographic.language_options.Czech': 'चेक',
    'app.demographic.language_options.Chinese': 'चीनी',
    'app.demographic.language_options.Croatian': 'क्रोएशियाई',
    'app.demographic.language_options.Danish': 'डेनिश',
    'app.demographic.language_options.Dari': 'दारी',
    'app.demographic.language_options.Dzongkha': 'ज़ोंग्खा',
    'app.demographic.language_options.Dutch': 'डच',
    'app.demographic.language_options.English': 'अंग्रेजी',
    'app.demographic.language_options.Esperanto': 'एस्पेरान्तो',
    'app.demographic.language_options.Estonian': 'एस्टोनियाई',
    'app.demographic.language_options.Faroese': 'फिरोज़ी/फ़रोइस',
    'app.demographic.language_options.Farsi': 'फ़ारसी',
    'app.demographic.language_options.Finnish': 'फिनिश',
    'app.demographic.language_options.French': 'फ्रान्सीसी/फ्रेंच',
    'app.demographic.language_options.Gaelic': 'गेलिक',
    'app.demographic.language_options.Galician': 'गैलिशियन्',
    'app.demographic.language_options.German': 'जर्मन',
    'app.demographic.language_options.Greek': 'ग्रीक',
    'app.demographic.language_options.Hebrew': 'हिब्रू',
    'app.demographic.language_options.Hindi': 'हिंदी',
    'app.demographic.language_options.Hungarian': 'हंगेरियन',
    'app.demographic.language_options.Icelandic': 'आइसलैंडिक',
    'app.demographic.language_options.Indonesian': 'इंडोनेशियाई',
    'app.demographic.language_options.Inuktitut_Eskimo': 'एस्किमो',
    'app.demographic.language_options.Italian': 'इतालवी',
    'app.demographic.language_options.Japanese': 'जापानी',
    'app.demographic.language_options.Khmer': 'ख्मेर/खमेर',
    'app.demographic.language_options.Korean': 'कोरियाई',
    'app.demographic.language_options.Kurdish': 'कुर्दी',
    'app.demographic.language_options.Laotian': 'लाओटियन/लाओ',
    'app.demographic.language_options.Latvian': 'लातवियाई',
    'app.demographic.language_options.Lappish': 'लैप भाषा',
    'app.demographic.language_options.Lithuanian': 'लिथुआनियाई',
    'app.demographic.language_options.Macedonian': 'मेसीडोनियन',
    'app.demographic.language_options.Malay': 'Malay मलायी/मलय',
    'app.demographic.language_options.Maltese': 'माल्टीज़',
    'app.demographic.language_options.Nepali': 'नेपाली',
    'app.demographic.language_options.Norwegian': 'नॉर्वेजियन',
    'app.demographic.language_options.Pashto': 'पश्तो',
    'app.demographic.language_options.Polish': 'पोलिश',
    'app.demographic.language_options.Portuguese': 'पोर्तुगीज़',
    'app.demographic.language_options.Romanian': 'रोमानियाई',
    'app.demographic.language_options.Russian': 'रूसी',
    'app.demographic.language_options.Scots': 'स्कॉट्स',
    'app.demographic.language_options.Serbian': 'सर्बियाई',
    'app.demographic.language_options.Slovak': 'स्लोवाक',
    'app.demographic.language_options.Slovenian': 'स्लोवेनियाई',
    'app.demographic.language_options.Somali': 'सोमाली',
    'app.demographic.language_options.Spanish': 'स्पेनिश',
    'app.demographic.language_options.Swedish': 'स्वीडिश',
    'app.demographic.language_options.Swahili': 'स्वाहिली',
    'app.demographic.language_options.Tagalog-Filipino': 'टागालोग-फिलिपिनो',
    'app.demographic.language_options.Tajik': 'ताजिक',
    'app.demographic.language_options.Tamil': 'तमिल',
    'app.demographic.language_options.Thai': 'थाई',
    'app.demographic.language_options.Tibetan': 'तिब्बती',
    'app.demographic.language_options.Tigrinya': 'तिग्रिन्या',
    'app.demographic.language_options.Tongan': 'टोंगन',
    'app.demographic.language_options.Turkish': 'तुर्की',
    'app.demographic.language_options.Turkmen': 'तुर्कमेन',
    'app.demographic.language_options.Ucrainian': 'यूक्रेनी',
    'app.demographic.language_options.Urdu': 'उर्दू',
    'app.demographic.language_options.Uzbek': 'उज़्बेक',
    'app.demographic.language_options.Vietnamese': 'वियतनामी',
    'app.demographic.language_options.Welsh': 'वेल्श',
    'app.demographic.language_options.Other': 'अन्य',
    'app.demographic.gender.male': 'पुरुष',
    'app.demographic.gender.female': 'महिला',
    'app.demographic.gender.self_identify': 'स्वयं वर्णन करना पसंद करते हैं',
    'app.demographic.marital.response_single': 'अविवाहित',
    'app.demographic.marital.response_married': 'विवाहित',
    'app.demographic.marital.response_divorced': 'तलाकशुदा',
    'app.demographic.marital.response_widowed': 'विधवा/विधुर',
    'app.demographic.marital.response_other': 'अन्य',
    'app.demographic.education_years.response_1': 'कोई औपचारिक शिक्षा नहीं.',
    'app.demographic.education_years.response_2': '1 साल',
    'app.demographic.education_years.response_3': '2 साल',
    'app.demographic.education_years.response_4': '3 साल',
    'app.demographic.education_years.response_5': '4 साल',
    'app.demographic.education_years.response_6': '5 साल',
    'app.demographic.education_years.response_7': '6 साल',
    'app.demographic.education_years.response_8': '7 साल',
    'app.demographic.education_years.response_9': '8 साल',
    'app.demographic.education_years.response_10': '9 साल',
    'app.demographic.education_years.response_11': '10 साल',
    'app.demographic.education_years.response_12': '11 साल',
    'app.demographic.education_years.response_13': '12 साल',
    'app.demographic.education_years.response_14': '13 साल',
    'app.demographic.education_years.response_15': '14 साल',
    'app.demographic.education_years.response_16': '15 साल',
    'app.demographic.education_years.response_17': '16 साल',
    'app.demographic.education_years.response_18': '17 साल',
    'app.demographic.education_years.response_19': '18 साल',
    'app.demographic.education_years.response_20': '19 साल',
    'app.demographic.education_years.response_21': '20 साल',
    'app.demographic.education_years.response_22': '21 साल',
    'app.demographic.education_years.response_23': '22 साल',
    'app.demographic.education_years.response_24': '23 साल',
    'app.demographic.education_years.response_25': '24 साल',
    'app.demographic.education_years.response_26': '25 साल',
    'app.demographic.education_years.response_27': '26 साल',
    'app.demographic.education_years.response_28': '27 साल',
    'app.demographic.education_years.response_29': '28 साल',
    'app.demographic.education_years.response_30': '29 साल',
    'app.demographic.education_years.response_31': '30 साल',
    'app.demographic.education_years.response_32': '30 वर्ष से अधिक',
    'app.demographic.ethnic.respose_asian': 'एशियाई',
    'app.demographic.ethnic.respose_african': 'काला या अफ़्रीकी',
    'app.demographic.ethnic.respose_latino': 'हिस्पैनिक या लैटिनो',
    'app.demographic.ethnic.respose_indigenous': 'स्वदेशी',
    'app.demographic.ethnic.respose_middle_eastern': 'मध्य पूर्वी',
    'app.demographic.ethnic.respose_white': 'सफ़ेद या कोकेशियान',
    'app.demographic.ethnic.respose_mixed': 'मिश्रित',
    'app.demographic.ethnic.respose_other': 'अन्य',
    'app.demographic.completing_bevi.response_before': 'किसी अंतर्राष्ट्रीय/अंतरसांस्कृतिक शिक्षण अनुभव से पहले (या शुरुआत में)',
    'app.demographic.completing_bevi.response_during': 'किसी अंतर्राष्ट्रीय/अंतरसांस्कृतिक शिक्षण अनुभव के दौरान (या बीच में)।',
    'app.demographic.completing_bevi.response_after': 'किसी अंतरराष्ट्रीय/अंतरसांस्कृतिक शिक्षण अनुभव के बाद (या अंत में)।',
    'app.demographic.completing_bevi.response_during_academic': 'शैक्षिक अभिविन्यास के दौरान और ऊपर दिए गए विकल्पों में से कोई भी नहीं।',
    'app.demographic.completing_bevi.response_end_graduation': 'अपने अंडरग्रेजुएट कॉलेज करियर के अंत में',
    'app.demographic.completing_bevi.response_other': 'अन्य - कृपया स्पष्ट करें',
    'app.demographic.primary_area_of_work.response_1': 'कला, संचार, मानविकी',
    'app.demographic.primary_area_of_work.response_2': 'व्यवहार, संस्कृति, समाज',
    'app.demographic.primary_area_of_work.response_3': 'व्यापार',
    'app.demographic.primary_area_of_work.response_4': 'शिक्षा',
    'app.demographic.primary_area_of_work.response_5': 'स्वास्थ्य एवं मानव सेवाएँ',
    'app.demographic.primary_area_of_work.response_6': 'विज्ञान और प्रौद्योगिकी',
    'app.demographic.primary_area_of_work.response_7': 'अन्य',
    'app.demographic.political_orientation.response_1': 'रूढ़िवादी/परंपरागत (उदहारण के लिए, मौजूदा कानूनों, प्रणालियों और जीवनशैली को बनाए रखने या संरक्षित करने का समर्थन करने वाला)',
    'app.demographic.political_orientation.response_2': 'उदारवादी की तुलना में अधिक रूढ़िवादी',
    'app.demographic.political_orientation.response_3': 'मध्यम/तटस्थ/न तो रूढ़िवादी न ही उदारवादी',
    'app.demographic.political_orientation.response_4': 'रूढ़िवादी की तुलना में अधिक उदारवादी',
    'app.demographic.political_orientation.response_5': 'उदारवादी/प्रगतिशील (उदाहरण के लिए, मौजूदा कानूनों, प्रणालियों और जीवनशैली को बदलने या सुधारने का समर्थन करने वाला)',
    'app.demographic.political_orientation.response_6': 'अन्य',
    'app.demographic.religion.response_1': 'अफ़्रीकी पारंपरिक धर्म',
    'app.demographic.religion.response_2': 'Agnosticism अनीश्वरवाद/अज्ञेयवाद',
    'app.demographic.religion.response_3': 'नास्तिकता',
    'app.demographic.religion.response_4': 'बहाई',
    'app.demographic.religion.response_5': 'बुद्ध धर्म',
    'app.demographic.religion.response_6': 'कोदैस्म',
    'app.demographic.religion.response_7': 'चीनी पारंपरिक धर्म',
    'app.demographic.religion.response_8': 'ईसाई धर्म',
    'app.demographic.religion.response_9': 'हिन्दू धर्म',
    'app.demographic.religion.response_10': 'स्वदेशी/देशज',
    'app.demographic.religion.response_11': 'इसलाम',
    'app.demographic.religion.response_12': 'जैन धर्म',
    'app.demographic.religion.response_13': 'ज्यूचे',
    'app.demographic.religion.response_14': 'यहूदी धर्म/जूदाईस्म',
    'app.demographic.religion.response_15': 'पगन धर्म',
    'app.demographic.religion.response_16': 'शिंटो',
    'app.demographic.religion.response_17': 'सिख धर्म',
    'app.demographic.religion.response_18': 'अध्यात्मवाद',
    'app.demographic.religion.response_19': 'आध्यात्मिक लेकिन कोई विशेष धार्मिक संबद्धता नहीं',
    'app.demographic.religion.response_20': 'तेनरिक्यो',
    'app.demographic.religion.response_21': 'इकाईवाद-सार्वभौमिकता (यूनिटेरियन-यूनिवर्सलिज़्म)',
    'app.demographic.religion.response_22': 'ज़ोरास्ट्रिज़्म',
    'app.demographic.religion.response_other': 'अन्य',
    'app.demographic.religion.no_response': 'मैं इस प्रश्न का उत्तर नहीं देना पसंद करूंगा/करुंगी',
    'app.demographic.income_level.response_1': 'ऊपरी तिहाई',
    'app.demographic.income_level.response_2': 'मध्य तिहाई',
    'app.demographic.income_level.response_3': 'निचला तिहाई',
    'app.demographic.academic_rank.response_1': 'ऊपरी तिहाई',
    'app.demographic.academic_rank.response_2': 'मध्य तिहाई',
    'app.demographic.academic_rank.response_3': 'निचला तिहाई',
    'app.demographic.participated_experiences.response_1': 'सेमिनार पाठ्यक्रम विशेष रूप से प्रथम वर्ष/नए छात्रों के लिए',
    'app.demographic.participated_experiences.response_2': 'विश्वविद्यालय द्वारा आयोजित शिक्षण समुदाय का सदस्य',
    'app.demographic.participated_experiences.response_3': 'गहन पाठ्यक्रम लेखन',
    'app.demographic.participated_experiences.response_4': 'किसी शिक्षक या पेशेवर सलाहकार के साथ शोध परियोजना',
    'app.demographic.participated_experiences.response_5': 'विश्वविद्यालय द्वारा प्रायोजित/स्वीकृत सेवा शिक्षण या समुदाय आधारित परियोजना',
    'app.demographic.participated_experiences.response_6': 'विश्वविद्यालय द्वारा प्रायोजित/स्वीकृत इंटर्नशिप',
    'app.demographic.participated_experiences.response_7': 'अंतिम परियोजना/डिग्री की समाप्ति परियोजना',
    'app.demographic.participated_experiences.response_8': 'सामान्य या साझा पाठ्यक्रमों का सेट',
    'app.demographic.participated_experiences.response_9': 'दूसरों के साथ मिलकर किये गए असाइनमेंट्स या परियोजनाएँ',
    'app.demographic.participated_experiences.response_10': 'विविधता/वैश्विक शिक्षण पाठ्यक्रम या विदेश में अध्ययन',
    'app.demographic.participated_experiences.response_11': 'आपके शैक्षिक कार्य या अनुभवों का इलेक्ट्रॉनिक पोर्टफोलियो (ईपोर्टफोलियो)।',
    'app.demographic.participated_experiences.response_12': 'ऑनलाइन शिक्षण (उदाहरण के लिए, वर्चुअल या वेब-आधारित) जिसमें अंतर्राष्ट्रीय, बहुसांस्कृतिक या अंतरसांस्कृतिक फोकस शामिल है।',
    'app.demographic.participated_experiences.response_13': 'ऑनलाइन शिक्षण (उदाहरण के लिए, वर्चुअल या वेब-आधारित) जिसमें अंतर्राष्ट्रीय, बहुसांस्कृतिक, या अंतरसांस्कृतिक फोकस शामिल नहीं है।',
    'app.demographic.participated_learning_courses.response_1': 'ऐसे पाठ्यक्रम में भाग लिया जिसमे बहुसांस्कृतिक या वैश्विक अध्ययन पर ध्यान केंद्रित हो',
    'app.demographic.participated_learning_courses.response_2': 'कैम्पस पर सह-पाठयक्रम बहुसांस्कृतिक, अंतर्राष्ट्रीय, या सांस्कृतिक कार्यक्रमों में भाग लिया',
    'app.demographic.participated_learning_courses.response_3': 'विदेश में सेवा शिक्षण पूरा किया',
    'app.demographic.participated_learning_courses.response_4': 'विदेश में इंटर्नशिप पूरी की',
    'app.demographic.participated_learning_courses.response_5': 'विदेश में पढ़ाई की',
    'app.demographic.participated_learning_courses.response_6': 'ऐसी आवासीय-शैक्षिक समुदाय में रहे थे जिसमें अंतर्राष्ट्रीय, बहुसांस्कृतिक, वैश्विक, या भाषा अध्ययन पर ध्यान केंद्रित हो',
    'app.demographic.participated_learning_courses.response_7': 'ऐसी भाषा का अध्ययन किया जो मेरी मूल भाषा नहीं थी',
    'app.demographic.participated_learning_courses.response_8': 'ऐसे रूममेट के साथ रहे जिसकी सांस्कृतिक परंपरा या बैकग्राउंड मुझसे अलग थी',
    'app.demographic.participated_learning_courses.response_9': 'ऐसी वर्चुअल एक्सचेंज (VE), कोलैबोरेटिव ऑनलाइन इंटरनेशनल लर्निंग (COIL), या अन्य ऑनलाइन/वेब-आधारित शिक्षा पूरी की या पूरी कर रहे हैं, जिसमें अंतर्राष्ट्रीय, बहुसांस्कृतिक, या अंतर-सांस्कृतिक ध्यान केंद्रित हो।',
    'app.demographic.plan_participate_experiences.response_1': 'पहले वर्ष/फ्रेशमैन छात्रों के लिए विशेष सेमिनार पाठ्यक्रम',
    'app.demographic.plan_participate_experiences.response_2': 'विश्वविद्यालय द्वारा आयोजित अध्ययन समुदाय का सदस्य',
    'app.demographic.plan_participate_experiences.response_3': 'गहन पाठ्यक्रम लेखन',
    'app.demographic.plan_participate_experiences.response_4': 'किसी शिक्षक या पेशेवर सलाहकार के साथ शोध परियोजना',
    'app.demographic.plan_participate_experiences.response_5': 'विश्वविद्यालय द्वारा प्रायोजित/स्वीकृत सेवा शिक्षण या समुदाय आधारित परियोजना', 
    'app.demographic.plan_participate_experiences.response_6': 'विश्वविद्यालय द्वारा प्रायोजित/स्वीकृत इंटर्नशिप',
    'app.demographic.plan_participate_experiences.response_7': 'अंतिम परियोजना/डिग्री की समाप्ति परियोजना',
    'app.demographic.plan_participate_experiences.response_8': 'सामान्य या साझा पाठ्यक्रमों का सेट',
    'app.demographic.plan_participate_experiences.response_9': 'दूसरों के साथ मिलकर किये गए असाइनमेंट्स या परियोजनाएँ',
    'app.demographic.plan_participate_experiences.response_10': 'विविधता/वैश्विक शिक्षण पाठ्यक्रम या विदेश में अध्ययन',
    'app.demographic.plan_participate_experiences.response_11': 'आपके शैक्षिक कार्य या अनुभवों का इलेक्ट्रॉनिक पोर्टफोलियो (ईपोर्टफोलियो)।',
    'app.demographic.plan_participate_experiences.response_12': 'ऑनलाइन शिक्षण (उदाहरण के लिए, वर्चुअल या वेब-आधारित) जिसमें अंतर्राष्ट्रीय, बहुसांस्कृतिक या अंतरसांस्कृतिक फोकस शामिल है।',
    'app.demographic.plan_participate_experiences.response_13': 'ऑनलाइन शिक्षण (उदाहरण के लिए, वर्चुअल या वेब-आधारित) जिसमें अंतर्राष्ट्रीय, बहुसांस्कृतिक, या अंतरसांस्कृतिक फोकस शामिल नहीं है।',
    'app.demographic.plan_participate_learning_courses.response_1': 'ऐसे पाठ्यक्रम में भाग लें जिसमें बहुसांस्कृतिक या वैश्विक अध्ययन पर ध्यान केंद्रित हो',
    'app.demographic.plan_participate_learning_courses.response_2': 'कैम्पस पर सह-पाठयक्रम बहुसांस्कृतिक, अंतर्राष्ट्रीय, या सांस्कृतिक कार्यक्रमों में भाग लें',
    'app.demographic.plan_participate_learning_courses.response_3': 'विदेश में सेवा शिक्षण पूरा करें',
    'app.demographic.plan_participate_learning_courses.response_4': 'विदेश में इंटर्नशिप पूरी करें',
    'app.demographic.plan_participate_learning_courses.response_5': 'विदेश में पढ़ाई करें',
    'app.demographic.plan_participate_learning_courses.response_6': 'ऐसी आवासीय-शैक्षिक समुदाय में रहे जिसमें अंतर्राष्ट्रीय, बहुसांस्कृतिक, वैश्विक, या भाषा अध्ययन पर ध्यान केंद्रित हो',
    'app.demographic.plan_participate_learning_courses.response_7': 'ऐसी भाषा का अध्ययन करें जो मेरी मातृभाषा नहीं थी',
    'app.demographic.plan_participate_learning_courses.response_8': 'ऐसे रूममेट के साथ रहें जिसकी सांस्कृतिक परंपरा या बैकग्राउंड मुझसे अलग हो',
    'app.demographic.plan_participate_learning_courses.response_9': 'ऐसी वर्चुअल एक्सचेंज (VE), कोलैबोरेटिव ऑनलाइन इंटरनेशनल लर्निंग (COIL), या अन्य ऑनलाइन/वेब-आधारित शिक्षा पूरी करें, जिसमें अंतर्राष्ट्रीय, बहुसांस्कृतिक, या अंतर-सांस्कृतिक ध्यान केंद्रित हो।',
    'app.demographic.time_in_other_country.response_not_spent': 'विदेश में कोई महत्वपूर्ण समय नहीं',
    'app.demographic.time_in_other_country.response_less_2_weeks': 'कम से कम 1 सप्ताह लेकिन 2 सप्ताह से कम',
    'app.demographic.time_in_other_country.response_less_1_month': ' कम से कम 2 सप्ताह लेकिन 1 महीने से कम',
    'app.demographic.time_in_other_country.response_less_3_months': ' कम से कम 1 महीना लेकिन 3 महीने से कम',
    'app.demographic.time_in_other_country.response_less_6_months': 'कम से कम 3 महीने लेकिन 6 महीने से कम',
    'app.demographic.time_in_other_country.response_less_1_year': 'कम से कम 6 महीने लेकिन 1 वर्ष से कम',
    'app.demographic.time_in_other_country.response_less_2_years': 'कम से कम 1 वर्ष लेकिन 2 वर्ष से कम',
    'app.demographic.time_in_other_country.response_less_5_years': 'कम से कम 2 वर्ष लेकिन 5 वर्ष से कम',
    'app.demographic.time_in_other_country.response_less_10_years': 'कम से कम 5 वर्ष लेकिन 10 वर्ष से कम',
    'app.demographic.time_in_other_country.response_10_or_more': '10 या अधिक वर्ष',
    'app.demographic.country.response_eua': 'संयुक्त राज्य अमेरिका',
    'app.demographic.country.response_afganistan': 'अफ़्ग़ानिस्तान',
    'app.demographic.country.response_albania': 'अल्बानिया',
    'app.demographic.country.response_algeria': 'अल्जीरिया',
    'app.demographic.country.response_american_samoa': 'अमेरिकी समोआ',
    'app.demographic.country.response_andorra': 'अंडोरा',
    'app.demographic.country.response_angola': 'अंगोला',
    'app.demographic.country.response_anguilla': ' एंगुइला',
    'app.demographic.country.response_antigua_barbuda': 'एंटीगुआ और बारबूडा',
    'app.demographic.country.response_argentia': 'अर्जेंटीना',
    'app.demographic.country.response_armenia': 'आर्मीनिया',
    'app.demographic.country.response_aruba': 'अरूबा',
    'app.demographic.country.response_australia': 'ऑस्ट्रेलिया',
    'app.demographic.country.response_austria': 'ऑस्ट्रिया',
    'app.demographic.country.response_azerbaijan': 'अज़रबाइजान',
    'app.demographic.country.response_bahamas': 'बहामास',
    'app.demographic.country.response_bahrain': 'बहरीन',
    'app.demographic.country.response_bangladesh': 'बांग्लादेश',
    'app.demographic.country.response_barbados': 'बारबाडोस',
    'app.demographic.country.response_belarus': 'बेलारूस ',
    'app.demographic.country.response_belgium': 'बेल्जियम',
    'app.demographic.country.response_belize': 'बेलीज़',
    'app.demographic.country.response_benin': 'बेनिन',
    'app.demographic.country.response_bermuda': 'बरमूडा',
    'app.demographic.country.response_bhutan': 'भूटान',
    'app.demographic.country.response_bolivia': 'बोलीविया',
    'app.demographic.country.response_bosnia_herzegovina': 'बोस्निया और हर्जेगोविना',
    'app.demographic.country.response_botswana': 'बोत्सवाना',
    'app.demographic.country.response_brazil': 'ब्राज़िल',
    'app.demographic.country.response_brunei': 'ब्रुनेई',
    'app.demographic.country.response_bulgaria': 'बुल्गारिया',
    'app.demographic.country.response_burkina_faso': 'बुर्किना फासो',
    'app.demographic.country.response_burundi': 'बुरूंडी',
    'app.demographic.country.response_cambodia': 'कंबोडिया',
    'app.demographic.country.response_cameroon': 'कैमरून',
    'app.demographic.country.response_canada': 'कनाडा',
    'app.demographic.country.response_cape_verde': 'केप वर्ड',
    'app.demographic.country.response_cayman_islands': 'केमन द्वीपसमूह',
    'app.demographic.country.response_central_african_republic': 'केन्द्रीय अफ़्रीकी गणराज्य',
    'app.demographic.country.response_chad': 'चाड',
    'app.demographic.country.response_chile': 'चिली',
    'app.demographic.country.response_china': 'चीन',
    'app.demographic.country.response_china_hong_kong_sar': 'हांग कांग',
    'app.demographic.country.response_colombia': 'कोलंबिया',
    'app.demographic.country.response_comoros': 'कोमोरोस',
    'app.demographic.country.response_congo': 'कांगो',
    'app.demographic.country.response_congo,_democratic_republic': 'कांगो, लोकतांत्रिक गणराज्य',
    'app.demographic.country.response_cook_islands': 'कुक द्वीपसमूह',
    'app.demographic.country.response_costa_rica': 'कोस्टा रिका',
    'app.demographic.country.response_cote_divoire': 'कोट डि आइवर',
    'app.demographic.country.response_croatia': 'क्रोएशिया',
    'app.demographic.country.response_cuba': 'क्यूबा',
    'app.demographic.country.response_cyprus': 'साइप्रस',
    'app.demographic.country.response_czech_republic': 'चेक रिपब्लिक',
    'app.demographic.country.response_denmark': 'डेनमार्क',
    'app.demographic.country.response_djibouti': 'जिबूती ',
    'app.demographic.country.response_dominica': 'डोमिनिका',
    'app.demographic.country.response_dominican_republic': 'डोमिनिकन गणराज्य',
    'app.demographic.country.response_ecuador': 'इक्वाडोर',
    'app.demographic.country.response_egypt': 'मिस्र',
    'app.demographic.country.response_el_salvador': 'एल साल्वाडोर ',
    'app.demographic.country.response_equatorial_guinea': 'इक्वेटोरियल गिनी/भूमध्यवर्ती गिनी',
    'app.demographic.country.response_eritrea': 'इरिट्रिया',
    'app.demographic.country.response_estonia': 'एस्तोनिया',
    'app.demographic.country.response_ethiopia': 'इथियोपिया',
    'app.demographic.country.response_euro_area': 'यूरो क्षेत्र',
    'app.demographic.country.response_falkland_islands': 'फ़ॉकलैंड आइलैंड',
    'app.demographic.country.response_fiji': 'फ़िजी',
    'app.demographic.country.response_finland': 'फिनलैंड',
    'app.demographic.country.response_france': 'फ्रांस',
    'app.demographic.country.response_french_guiana': 'फ्रेंच गयाना',
    'app.demographic.country.response_french_polynesia': 'फ़्रेंच पोलिनेशिया',
    'app.demographic.country.response_gabon': 'गैबॉन',
    'app.demographic.country.response_gambia': 'गाम्बिया',
    'app.demographic.country.response_georgia': 'जॉर्जिया',
    'app.demographic.country.response_germany': 'जर्मनी',
    'app.demographic.country.response_ghana': 'घाना',
    'app.demographic.country.response_gibraltar': 'जिब्राल्टर',
    'app.demographic.country.response_greece': 'ग्रीस',
    'app.demographic.country.response_grenada': 'ग्रेनेडा',
    'app.demographic.country.response_guadeloupe': 'ग्वाडेलोप',
    'app.demographic.country.response_guam': 'गुआम',
    'app.demographic.country.response_guatemala': 'ग्वाटेमाला',
    'app.demographic.country.response_guernsey': 'ग्वेर्नसे',
    'app.demographic.country.response_guinea': 'गिनी',
    'app.demographic.country.response_guinea_bissau': 'गिनी बिसाऊ',
    'app.demographic.country.response_guyana': 'गुयाना',
    'app.demographic.country.response_haiti': 'हैती',
    'app.demographic.country.response_honduras': 'होंडुरस',
    'app.demographic.country.response_hungary': 'हंगरी',
    'app.demographic.country.response_iceland': 'आइसलैंड',
    'app.demographic.country.response_india': 'भारत',
    'app.demographic.country.response_indonesia': 'इंडोनेशिया',
    'app.demographic.country.response_iran': 'ईरान',
    'app.demographic.country.response_iraq': 'इराक',
    'app.demographic.country.response_ireland': 'आयरलैंड',
    'app.demographic.country.response_isle_of_man': 'आइल ऑफ़ मैन',
    'app.demographic.country.response_israel': 'इज़राइल',
    'app.demographic.country.response_italy': 'इटली',
    'app.demographic.country.response_jamaica': 'जमैका',
    'app.demographic.country.response_japan': 'जापान',
    'app.demographic.country.response_jersey': 'जर्सी',
    'app.demographic.country.response_jordan': 'जॉर्डन',
    'app.demographic.country.response_kazakhstan': 'कज़ाख़िस्तान',
    'app.demographic.country.response_kenya': 'केन्या',
    'app.demographic.country.response_kiribati': 'किरिबाती',
    'app.demographic.country.response_kosovo': 'कोसोवो',
    'app.demographic.country.response_kuwait': 'कुवैत',
    'app.demographic.country.response_kyrgyzstan': 'किर्गिज़स्तान',
    'app.demographic.country.response_laos': 'लाओस',
    'app.demographic.country.response_latvia': 'लातविया',
    'app.demographic.country.response_lebanon': 'लेबनान',
    'app.demographic.country.response_lesotho': 'लेसोथो',
    'app.demographic.country.response_liberia': 'लाइबेरिया',
    'app.demographic.country.response_libya': 'लीबिया',
    'app.demographic.country.response_liechtenstein': 'लिकटेनस्टाइन',
    'app.demographic.country.response_lithuania': 'लिथुआनिया',
    'app.demographic.country.response_luxembourg': 'लक्ज़मबर्ग',
    'app.demographic.country.response_macau': 'मकाउ',
    'app.demographic.country.response_macedonia': 'मैसेडोनिया.',
    'app.demographic.country.response_madagascar': 'मेडागास्कर',
    'app.demographic.country.response_malaysia': 'मलेशिया',
    'app.demographic.country.response_malawi': 'मलावी',
    'app.demographic.country.response_maldives': 'मालदीव',
    'app.demographic.country.response_mali': 'माली',
    'app.demographic.country.response_malta': 'माल्टा',
    'app.demographic.country.response_mariana': 'मारियाना',
    'app.demographic.country.response_marshall_islands': 'मार्शल द्वीपसमूह',
    'app.demographic.country.response_martinique': 'मार्टीनिक',
    'app.demographic.country.response_mauritania': 'मॉरिटानिया',
    'app.demographic.country.response_mauritius': 'मॉरीशस',
    'app.demographic.country.response_mexico': 'मेक्सिको',
    'app.demographic.country.response_micronesia': 'माइक्रोनेशिया',
    'app.demographic.country.response_moldova': 'मोल्दोवा/मॉल्डोवा',
    'app.demographic.country.response_monaco': 'मोनाको',
    'app.demographic.country.response_mongolia': 'मंगोलिया',
    'app.demographic.country.response_montserrat': 'मोंटसेराट',
    'app.demographic.country.response_montenegro': 'मोंटेनेग्रो',
    'app.demographic.country.response_morocco': 'मोरक्को',
    'app.demographic.country.response_mozambique': 'मोज़ाम्बिक',
    'app.demographic.country.response_myanmar': 'म्यांमार',
    'app.demographic.country.response_namibia': 'नामीबिया',
    'app.demographic.country.response_nepal': 'नेपाल',
    'app.demographic.country.response_netherland_antilles': 'नीदरलैंड एंटिलीज़',
    'app.demographic.country.response_netherlands': 'नीदरलैंड',
    'app.demographic.country.response_new_caledonia': 'न्यू कैलेडोनिया',
    'app.demographic.country.response_new_zealand': 'न्यूज़ीलैण्ड',
    'app.demographic.country.response_nicaragua': 'निकारागुआ',
    'app.demographic.country.response_niger': 'नाइजर',
    'app.demographic.country.response_nigeria': 'नाईजीरिया',
    'app.demographic.country.response_north_korea': 'उत्तर कोरिया',
    'app.demographic.country.response_norway': 'नॉर्वे',
    'app.demographic.country.response_oman': 'ओमान',
    'app.demographic.country.response_pakistan': 'पाकिस्तान',
    'app.demographic.country.response_palau': 'पलाउ',
    'app.demographic.country.response_panama': 'पनामा',
    'app.demographic.country.response_papua_new_guinea': 'पापुआ न्यू गिनी',
    'app.demographic.country.response_paraguay': 'पैराग्वे',
    'app.demographic.country.response_peru': 'पेरू',
    'app.demographic.country.response_phillipines': 'फ़िलीपीन्स',
    'app.demographic.country.response_poland': 'पोलैंड',
    'app.demographic.country.response_portugal': 'पुर्तगाल',
    'app.demographic.country.response_puerto_rico': 'प्यूर्टो रिको',
    'app.demographic.country.response_qatar': 'क़तर',
    'app.demographic.country.response_reunion': 'रीयूनियन',
    'app.demographic.country.response_romania': 'रोमानिया',
    'app.demographic.country.response_russian_federation': 'रूसी संघ',
    'app.demographic.country.response_rwanda': 'रवांडा',
    'app.demographic.country.response_st_helena': 'सेंट हेलेना',
    'app.demographic.country.response_st_kitts-nevis': 'सेंट किट्स और नेविस',
    'app.demographic.country.response_st_lucia': 'सेंट लूसिया',
    'app.demographic.country.response_st_vincent_grenadines': 'सेंट विंसेंट एवं ग्रेनाडींस',
    'app.demographic.country.response_stateless': 'Stateless राज्यविहीन',
    'app.demographic.country.response_samoa': 'समोआ',
    'app.demographic.country.response_san_marino': 'सान मारिनो',
    'app.demographic.country.response_sao_tome_principe': 'साओ टॉमे और प्रिंसिपे ',
    'app.demographic.country.response_saudi_arabia': 'सउदी अरब',
    'app.demographic.country.response_senegal': 'सेनेगल',
    'app.demographic.country.response_seychelles': 'सेशेल्स',
    'app.demographic.country.response_serbia': 'सर्बिया',
    'app.demographic.country.response_sierra_leone': 'सिएरा लियोन',
    'app.demographic.country.response_singapore': 'सिंगापुर',
    'app.demographic.country.response_slovak_republic': 'स्लोवाक गणराज्य',
    'app.demographic.country.response_slovenia': 'स्लोवेनिया',
    'app.demographic.country.response_solomon_islands': 'सोलोमन द्वीपसमूह',
    'app.demographic.country.response_somalia': 'सोमालिया',
    'app.demographic.country.response_south_africa': 'दक्षिण अफ्रीका',
    'app.demographic.country.response_south_korea': 'दक्षिण कोरिया',
    'app.demographic.country.response_spain': 'स्पेन',
    'app.demographic.country.response_sri_lanka': 'श्रीलंका',
    'app.demographic.country.response_sudan': 'सूडान',
    'app.demographic.country.response_suriname': 'सूरीनाम',
    'app.demographic.country.response_swaziland': 'स्वाजीलैंड',
    'app.demographic.country.response_sweden': 'स्वीडन',
    'app.demographic.country.response_switzerland': 'स्विट्ज़रलैंड',
    'app.demographic.country.response_syria': 'सीरिया',
    'app.demographic.country.response_taiwan': 'ताइवान',
    'app.demographic.country.response_tajikistan': 'ताजिकिस्तान',
    'app.demographic.country.response_tanzania': 'तंजानिया',
    'app.demographic.country.response_thailand': 'थाईलैंड',
    'app.demographic.country.response_togo': 'टोगो',
    'app.demographic.country.response_timor_leste': 'पूर्वी तिमोर/तिमोर - लेस्ते',
    'app.demographic.country.response_tonga': 'टोंगा',
    'app.demographic.country.response_trinidad_tobago': 'त्रिनिदाद और टोबैगो',
    'app.demographic.country.response_tunisia': 'ट्यूनीशिया',
    'app.demographic.country.response_turkey': 'तुर्की',
    'app.demographic.country.response_turkmenistan': 'तुर्कमेनिस्तान',
    'app.demographic.country.response_turks_caicos_is': 'टर्क्स ऐंड कैकोस',
    'app.demographic.country.response_tuvalu': 'तुवालु',
    'app.demographic.country.response_uganda': 'युगांडा',
    'app.demographic.country.response_ukraine': 'यूक्रेन',
    'app.demographic.country.response_united_arab_erimates': 'संयुक्त अरब अमीरात',
    'app.demographic.country.response_united_kingdom': 'यूनाइटेड किंगडम',
    'app.demographic.country.response_uraguay': 'उरुग्वे',
    'app.demographic.country.response_uzbekistan': 'उज़्बेकिस्तान',
    'app.demographic.country.response_vanuatu': 'वानूआतू',
    'app.demographic.country.response_venezuela': 'वेनेज़ुएला',
    'app.demographic.country.response_vietnam': 'वियतनाम',
    'app.demographic.country.response_virgin_islands': 'वर्जिन आइलैंड्स',
    'app.demographic.country.response_west_bank_and_gaza': 'वेस्ट बैंक और गाजा',
    'app.demographic.country.response_yemen': 'यमन',
    'app.demographic.country.response_zambia': 'ज़ाम्बिया',
    'app.demographic.country.response_zimbabwe': 'ज़िम्बाब्वे',
    'app.demographic.level_of_fluency.i_do_not_speak': 'मैं अपनी मूल भाषा के अलावा कोई अन्य भाषा नहीं बोलती/बोलता/',
    'app.demographic.level_of_fluency.minimally_fluent': 'न्यूनतम धाराप्रवाह',
    'app.demographic.level_of_fluency.somewhat_fluent': 'कुछ हद तक धाराप्रवाह',
    'app.demographic.level_of_fluency.moderately_fluent': 'मध्यम धाराप्रवाह',
    'app.demographic.level_of_fluency.mostly_fluent': 'अधिकतर धाराप्रवाह',
    'app.demographic.level_of_fluency.fluent': 'धाराप्रवाह',
    'app.demographic.level_of_interest.not_applicable': 'लागू नहीं है',
    'app.demographic.level_of_interest.extremely_low': 'अत्यंत कम',
    'app.demographic.level_of_interest.very_low': 'बहुत कम',
    'app.demographic.level_of_interest.low': 'कम',
    'app.demographic.level_of_interest.neutral': 'निष्पक्ष/तटस्थ ',
    'app.demographic.level_of_interest.high': 'उच्च',
    'app.demographic.level_of_interest.very_high': 'बहुत उच्च',
    'app.demographic.level_of_interest.extremely_high': 'अत्यंत उच्च',
    'app.demographic_slider_default_option': 'कृपया अपने उत्तर का चयन करने के लिए कर्सर को हिलाएँ।',
    'app.demographic.formal_education.some_high_school_or_less': 'कुछ हाई स्कूल या उससे कम',
    'app.demographic.formal_education.high_school_graduate': 'उच्च विद्यालय के स्नातक',
    'app.demographic.formal_education.some_college': 'कुछ कॉलेज',
    'app.demographic.formal_education.college_degree': 'कॉलेज की डिग्री',
    'app.demographic.formal_education.some_graduate_school': 'कुछ स्नातक विद्यालय',
    'app.demographic.formal_education.graduate_degree': 'स्नातक डिग्री (जैसे, मास्टर डिग्री)',
    'app.demographic.formal_education.professional_degree': 'व्यावसायिक डिग्री (जैसे, कानून)',
    'app.demographic.formal_education.doctoral_degree': 'डॉक्टरेट की डिग्री',
}

export const demographicQuestionsHI: DemographicPageMessages =
    { ...demographicQuestions, ...demographicResponseOptions }
