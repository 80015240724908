import { ReportsMessages } from '../types';

export const reportsJA: ReportsMessages = {
    'app.reports.insuficient_data': 'データ数が十分ではない/同質のデータのみ、などの理由でレポートを作成することができませんでした。',
    'app.reports.decile': 'Decile',
    'app.reports.group_report.title': 'グループ･レポート',
    'app.reports.longitudinal.title': ' T1/T2/Txレポート',
    'app.reports.comparative_by_group.title': 'グループ比較レポート',
    'app.reports.comparative_by_individual.title': '個人比較レポート',
    'app.report.menu.introduction': 'Report Information',
    'app.report.menu.basic_descriptive_statistics': 'Background Domain',
    'app.report.menu.aggregate_profile': 'Aggregate Profile',
    'app.report.menu.decile_profile': 'Decile Profile',
    'app.report.menu.aggregate_by_demographics': 'Aggregate by Demographics',
    'app.report.menu.background_domain_contrast': 'Background Domain Contrast',
    'app.report.menu.profile_background_contrast': 'Background Domain Contrast',
    'app.report.menu.profile_contrast': 'Profile Contrast',
    'app.report.menu.experiential_reflection_items': 'Experiential Reflection Items',
    'app.report.menu.country_of_origin': '出身国',
    'app.report.menu.gender': 'ジェンダー',
    'app.report.menu.education': '正規教育の期間',
    'app.report.menu.ethnicity': '民族的背景',
    'app.report.menu.income': '収入･資産レベル',
    'app.report.menu.interest': '国際的経験への関心度',
    'app.report.menu.politics': '政治的志向',
    'app.report.menu.religion': '宗教的志向',
    'app.report.menu.satisfaction': '国際的経験の満足度',
    'app.report.menu.question_1': 'Question 1',
    'app.report.menu.question_2': 'Question 2',
    'app.report.menu.question_3': 'Question 3',
    'app.report.menu.narrative_report': 'Narrative Report',
    'app.report.introduction.create_date': '作成日:',
    'app.report.introduction.created_by': 'User:',
    'app.report.introduction.program_name': 'プログラム:',
    'app.report.introduction.group_name': 'プログラム/サブグループ:',
    'app.report.introduction.number_of_participants': '受験者数:',
    'app.report.introduction.visualized_by': '作成者:',
    'app.report.scales.consistency': 'Consistency',
    'app.report.scales.congruency': 'Congruency',
    'app.report.scales.negative_life_events': 'Negative Life Events',
    'app.report.scales.needs_closure': 'Needs Closure',
    'app.report.scales.needs_fulfillment': 'Needs Fulfillment',
    'app.report.scales.identity_diffusion': 'Identity Diffusion',
    'app.report.scales.basic_openness': 'Basic Openness',
    'app.report.scales.self_certitude': 'Self Certitude',
    'app.report.scales.basic_determinism': 'Basic Determinism',
    'app.report.scales.socioemotional_convergence': 'Socioemotional Convergence',
    'app.report.scales.physical_resonance': 'Physical Resonance',
    'app.report.scales.emotional_attunement': 'Emotional Attunement',
    'app.report.scales.self_awareness': 'Self Awareness',
    'app.report.scales.meaning_quest': 'Meaning Quest',
    'app.report.scales.religious_traditionalism': 'Religious Traditionalism',
    'app.report.scales.gender_traditionalism': 'Gender Traditionalism',
    'app.report.scales.sociocultural_openness': 'Sociocultural Openness',
    'app.report.scales.ecological_resonance': 'Ecological Resonance',
    'app.report.scales.global_resonance': 'Global Resonance',
    'app.report.aggregate_profile.high': 'High',
    'app.report.aggregate_profile.low': 'Low',
    'app.report.aggregate_profile.average': 'Average',
    'app.report.background_statistic_item.income_participants': '受検者{1}人中{0}人 - {2} %',
    'app.report.background_statistic_item.background_information': 'Background Information',
    'app.report.background_statistic_item.lowest': '下位:',
    'app.report.background_statistic_item.middle': '中位:',
    'app.report.background_statistic_item.highest': '上位:',
    'app.report.background_statistic_item.age': '年齢',
    'app.report.background_statistic_item.average_age': '平均年齢:',
    'app.report.background_statistic_item.answer': '回答:',
    'app.report.background_statistic_item.not_answer': '未回答:',
    'app.report.background_statistic_item.range': '範囲:',
    'app.report.background_statistic_item.gender': 'ジェンダー',
    'app.report.background_statistic_item.ethnic_background': '民族的背景',
    'app.report.background_statistic_item.country_of_origin': '出身国',
    'app.report.background_statistic_item.raised_in': 'Raised in成長した国',
    'app.report.background_statistic_item.not_raised_in': 'Not Raised in以下の国以外で成長- ',
    'app.report.background_statistic_item.details': '詳細',
    'app.report.background_statistic_item.years_of_education': '正規教育の期間',
    'app.report.background_statistic_item.income': '収入･資産レベル',
    'app.report.background_statistic_item.average': '平均:',
    'app.report.background_statistic_item.marital_status': '配偶者',
    'app.report.background_statistic_item.religion': '宗教/スピリチュアリティ',
    'app.report.background_statistic_item.interest': '国際的経験への関心度',
    'app.report.background_statistic_item.satisfaction': '国際的経験の満足度',
    'app.report.background_statistic_item.domain_scores': 'Domain Scores',
    'app.report.background_statistic_item.validity': 'Validity',
    'app.report.background_statistic_item.average_missing_items': 'Average Missing Items',
    'app.report.background_statistic_item.average_quantitative_completion_time': 'Average Quantitative Completion Time',
    'app.report.background_statistic_item.full_scale': 'Full Scale',
    'app.report.background_statistic_item.formative_variables': 'I. Formative Variables',
    'app.report.background_statistic_item.fulfillment_of_core_needs': 'II. Fulfillment of Core Needs',
    'app.report.background_statistic_item.tolerance_of_disequilibrium': 'III. Tolerance of Disequilibrium',
    'app.report.background_statistic_item.critical_thinking': 'IV. Critical Thinking',
    'app.report.background_statistic_item.self_access': 'V. Self Access',
    'app.report.background_statistic_item.other_access': 'VI. Other Access',
    'app.report.background_statistic_item.global_access': 'VII. Global Access',
    'app.report.decile_profile.high': 'High',
    'app.report.decile_profile.low': 'Low',
    'app.report.decile_profile.average': 'Average',
    'app.report.background_domain_contrast.title': 'Background Domain Contrast',
    'app.report.background_domain_contrast.lowest_full_scale': 'Lowest Full Scale',
    'app.report.background_domain_contrast.middle_full_scale': 'Middle Full Scale',
    'app.report.background_domain_contrast.highest_full_scale': 'Highest Full Scale',
    'app.report.background_domain_contrast.mean': 'Mean',
    'app.report.background_domain_contrast.range': 'Range',
    'app.report.background_domain_contrast.background_information': 'Background Information',
    'app.report.profile_contrast.lowest': 'Lowest',
    'app.report.profile_contrast.middle': 'Middle',
    'app.report.profile_contrast.highest': 'Highest',
    'app.report.profile_contrast.lowest_optimal_profiles': 'Lowest Optimal Profiles',
    'app.report.profile_contrast.middle_optimal_profiles': 'Middle Optimal Profiles',
    'app.report.profile_contrast.highest_optimal_profiles': 'Highest Optimal Profiles',
    'app.report.aggregate_profile.country_of_origin.selected_country': 'Selected Country',
    'app.report.aggregate_profile.country_of_origin.selected_countries': 'Selected Countries',
    'app.report.aggregate_profile.religious_orientation.selected_ethnic': 'Selected Ethnic',
    'app.report.aggregate_profile.religious_orientation.selected_ethnics': 'Selected Ethnics',
    'app.report.aggregate_profile.country_of_origin.other': 'Other',
    'app.report.aggregate_profile.religious_orientation.other': 'Other',
    'app.report.aggregate_profile.parents_country.same': 'Same',
    'app.report.aggregate_profile.parents_country.different': 'Different',
    'app.report.aggregate_profile.relationship_status.selected_relationship_status': 'Selected Relationship Status',
    'app.report.aggregate_profile.religion.selected_religion': 'Selected Religion',
    'app.report.aggregate_profile.relationship_status.other': 'Other',
    'app.report.aggregate_profile.gender.male': 'Male',
    'app.report.aggregate_profile.gender.female': 'Female',
    'app.report.aggregate_profile.gender.self_identify': 'Self Identify',
    'app.report.aggregate_profile.education.lowest_30': 'Lowest 30%',
    'app.report.aggregate_profile.education.highest_30': 'Highest 30%',
    'app.report.aggregate_profile.ethnicity.selected_ethnicity': 'Selected ethnicity',
    'app.report.aggregate_profile.ethnicity.selected_ethnicities': 'Selected ethnicities',
    'app.report.aggregate_profile.ethnicity.other': 'Other',
    'app.report.aggregate_profile.income.bottom_third': 'Bottom third',
    'app.report.aggregate_profile.income.middle_third': 'Middle third',
    'app.report.aggregate_profile.income.top_third': 'Top third',
    'app.report.aggregate_profile.interest.low': 'Low',
    'app.report.aggregate_profile.interest.middle': 'Middle',
    'app.report.aggregate_profile.interest.high': 'High',
    'app.report.aggregate_profile.politics.low': 'Low',
    'app.report.aggregate_profile.politics.middle': 'Middle',
    'app.report.aggregate_profile.politics.high': 'High',
    'app.report.aggregate_profile.age.low': 'Low',
    'app.report.aggregate_profile.age.middle': 'Middle',
    'app.report.aggregate_profile.age.high': 'High',
    'app.report.aggregate_profile.religion.low': 'Low',
    'app.report.aggregate_profile.religion.middle': 'Middle',
    'app.report.aggregate_profile.religion.high': 'High',
    'app.report.aggregate_profile.satisfaction.low': 'Low',
    'app.report.aggregate_profile.satisfaction.middle': 'Middle',
    'app.report.aggregate_profile.satisfaction.high': 'High',
    'app.report.aggregate_profile.mother_father_education.low': 'Low',
    'app.report.aggregate_profile.mother_father_education.middle': 'Middle',
    'app.report.aggregate_profile.mother_father_education.high': 'High',
    'app.report.aggregate_profile.time_spent_other_countries.low': 'Low',
    'app.report.aggregate_profile.time_spent_other_countries.middle': 'Middle',
    'app.report.aggregate_profile.time_spent_other_countries.high': 'High',
    'app.report.aggregate_profile.minority_group.minority': 'Minority',
    'app.report.aggregate_profile.minority_group.majority': 'Majority',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work': 'Selected Primary Area of Work',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work': 'Selected Primary Areas of Work',
    'app.report.aggregate_profile.primary_area_of_work.other': 'Other',
    'app.report.aggregate_profile.years_of_language_study.low': 'Low',
    'app.report.aggregate_profile.years_of_language_study.middle': 'Middle',
    'app.report.aggregate_profile.years_of_language_study.high': 'High',
    'app.report.aggregate_profile.academic_rank.low': 'Low',
    'app.report.aggregate_profile.academic_rank.middle': 'Middle',
    'app.report.aggregate_profile.academic_rank.high': 'High',
    'app.report.aggregate_profile.visited_countries.low': 'Low',
    'app.report.aggregate_profile.visited_countries.middle': 'Middle',
    'app.report.aggregate_profile.visited_countries.high': 'High',
    'app.report.aggregate_profile.fluency.low': 'Don\'t or Minimum',
    'app.report.aggregate_profile.fluency.middle': 'Sumewhat or Modestly',
    'app.report.aggregate_profile.fluency.high': 'Mostly Fluent or Fluent',
    'app.report.aggregate_demographic.core_demographics': 'Core Demographics',
    'app.report.aggregate_demographic.parental_background': 'Parental Background',
    'app.report.aggregate_demographic.political_religious_inclinations': 'Political and Religious Inclinations',
    'app.report.aggregate_demographic.education_background': 'Education Background',
    'app.report.aggregate_demographic.international_cultural_experiences': 'International and Cultural Experiences',
    'app.report.aggregate_demographic.age': 'Age',
    'app.report.aggregate_demographic.gender': 'Gender',
    'app.report.aggregate_demographic.relationship_status': 'Relationship Status',
    'app.report.aggregate_demographic.country_origin': 'Country of Origin',
    'app.report.aggregate_demographic.parent_country': 'Parent\'s Country',
    'app.report.aggregate_demographic.mother_education': 'Mother\'s Education',
    'app.report.aggregate_demographic.father_education': 'Father\'s Education',
    'app.report.aggregate_demographic.ethnic_background': 'Ethnic Background',
    'app.report.aggregate_demographic.minority_group': 'Minority Group',
    'app.report.aggregate_demographic.political_orientation': 'Political Orientation',
    'app.report.aggregate_demographic.religious_orientation': 'Religious Orientation',
    'app.report.aggregate_demographic.religious_commitment': 'Religious Commitment',
    'app.report.aggregate_demographic.income_level': 'Income Level',
    'app.report.aggregate_demographic.years_education': 'Years of Education',
    'app.report.aggregate_demographic.primary_area_work': 'Primary area or work',
    'app.report.aggregate_demographic.academic_rank': 'Academic Rank',
    'app.report.aggregate_demographic.time_spent_countries': 'Time spent in other countries',
    'app.report.aggregate_demographic.visited_countries': 'Visited Countries',
    'app.report.aggregate_demographic.personal_interest': 'Personal interest',
    'app.report.aggregate_demographic.study_abroad_satisfaction': 'Study abroad satisfaction',
    'app.report.aggregate_demographic.fluency': 'Fluency',
    'app.report.aggregate_demographic.years_language_study': 'Years of Language Study',
    'app.report.comparative_individual_report_result_title': 'BEVI 個別変更レポートの結果',
    'app.report.comparative_individual_report_result_description': 'ある政権から次の政権へと、BEVI のさまざまな変化の「帯」の間を移動しています (つまり、ある政権から次の政権へと大幅な変化が見られる可能性があります)。 「Being Bevi」では、異なる BEVI 管理の間で実際に起こっている変化について詳しく知ることができます (つまり、Being Bevi は Beviverse 内に存在する AI エンティティです -- 右下隅の円をクリックします)。 以下に色付きのテキストがある場合は、通常、BEVI の投与ごとにスコアが大幅に変化したことを意味します。 色付きのテキストは、BEVI のさまざまな投与にわたってこの変化が起こった方向を示しています (たとえば、通常は BEVI を初めて受けてから 2 回目までですが、実際には BEVI を何回受けたかによって異なります)。 BEVI を取得し、どの比較を行っているか)。 さらに詳しく知りたい場合、BEVI の 17 スケールでの具体的な変更を確認するには、BEVI 管理者に連絡するか、BEVI チームに連絡してください。',
    'app.report.comparative_individual_report_result_description_no_change': 'ある政権から次の政権に至るまで、あなたは同じ変化の「帯」内にとどまることになります。 「Being Bevi」では、ある BEVI 管理から次の BEVI 管理までの間に実際に起こっている変化について詳しく知ることができます (つまり、Being Bevi は Beviverse 内に存在する AI エンティティです -- 右下隅の円をクリックしてください) ）。 さらに詳しく知りたい場合、BEVI の 17 スケールでの具体的な変更を確認するには、BEVI 管理者に連絡するか、BEVI チームに連絡してください。',
    'app.report.partner_report_result_title': 'BEVI パートナー レポートの結果',
    'app.report.partner_change_report_result_title': 'BEVI パートナー変更レポートの結果',
    'app.report.permission.modal_title': 'Dear BEVI Participant',
    'app.report.permission.modal_description': `
    <h5>あなたはすでに BEVI を取得することに同意していますが、BEVI レポートの作成に協力する前に、同意を求めています。</h5>
    <br />
    <p>
    1) あなたの個人的なレポートについて話し合う 1 つ以上のセッションに参加したいこと、および
    </p>
    <p>
    2) <b>[#USERS#]</b> があなたの個々のレポート (説明、スコアなど) を確認し、BEVI レポートの意味や影響について話し合うことを許可していること。
    もちろん、BEVI の同意書に記載されているように、個人の結果は完全に機密扱いとなります。 ご質問がある場合、またはさらに詳しい情報が必要な場合は、協力している BEVI 管理者にお問い合わせください。
    </p>
    <p>
    上記 2 つの条項を理解し、同意する場合は、下の「同意する」ボタンをクリックしてください。
    </p>
    <center>BEVIにご興味をお持ちいただきありがとうございます。</center>
    `,
    'app.report.permission.pending': '保留中',
    'app.report.permission.approved': '承認された',
    'app.report.permission.rejected': '拒否されました',
    'app.report.permission.approve': '同意します',
    'app.report.permission.reject': '私は同意しない',
    "app.reports.selectAll": "すべて選択",
    "app.reports.deselectAll": "すべて選択解除"
}