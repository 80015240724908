import { ReportsMessages } from '../types';

export const reportsCA: ReportsMessages = {
    'app.reports.insuficient_data': 'No hi ha dades suficients per a generar l’informe.',
    'app.reports.decile': 'Decil',
    'app.reports.group_report.title': 'Informe de grup',
    'app.reports.longitudinal.title': 'Informe longitudinal',
    'app.reports.comparative_by_group.title': 'Informe entre grups',
    'app.reports.comparative_by_individual.title': "Dins l'informe del grup",
    'app.report.menu.introduction': 'Informació sobre l’informe',
    'app.report.menu.basic_descriptive_statistics': 'Domini d’informació de fons',
    'app.report.menu.aggregate_profile': 'Perfil agregat',
    'app.report.menu.decile_profile': 'Perfil decil',
    'app.report.menu.aggregate_by_demographics': 'Agregat per dades demogràfiques',
    'app.report.menu.background_domain_contrast': 'Contrast de dominis de fons',
    'app.report.menu.profile_background_contrast': 'Contrast de perfil de fons',
    'app.report.menu.profile_contrast': 'Contrast de perfils',
    'app.report.menu.experiential_reflection_items': 'Ítems de reflexió experiencial',
    'app.report.menu.country_of_origin': 'País d’origen',
    'app.report.menu.gender': 'Gènere',
    'app.report.menu.education': 'Estudis',
    'app.report.menu.ethnicity': 'Etnicitat',
    'app.report.menu.income': 'Ingressos',
    'app.report.menu.interest': 'Interès',
    'app.report.menu.politics': 'Política',
    'app.report.menu.religion': 'Religiós',
    'app.report.menu.satisfaction': 'Satisfacció',
    'app.report.menu.question_1': 'Qüestió 1',
    'app.report.menu.question_2': 'Qüestió 2',
    'app.report.menu.question_3': 'Qüestió 3',
    'app.report.menu.narrative_report': 'Reportatge narratiu',
    'app.report.introduction.create_date': 'Creat el:',
    'app.report.introduction.created_by': 'Usuari:',
    'app.report.introduction.program_name': 'Programa o experiència:',
    'app.report.introduction.group_name': 'BEVI administració/ions:',
    'app.report.introduction.number_of_participants': 'Nombre de participants:',
    'app.report.introduction.visualized_by': 'Vist per:',
    'app.report.scales.consistency': 'Coherència',
    'app.report.scales.congruency': 'Congruència',
    'app.report.scales.negative_life_events': 'Esdeveniments negatius vitals',
    'app.report.scales.needs_closure': 'Tancament de necessitats',
    'app.report.scales.needs_fulfillment': 'Satisfacció de necessitats',
    'app.report.scales.identity_diffusion': 'Difusió de la identitat',
    'app.report.scales.basic_openness': 'Obertura bàsica',
    'app.report.scales.self_certitude': 'Certitud en si mateix ',
    'app.report.scales.basic_determinism': 'Determinisme bàsic',
    'app.report.scales.socioemotional_convergence': 'Convergència socioemocional',
    'app.report.scales.physical_resonance': 'Ressonància física',
    'app.report.scales.emotional_attunement': 'Sintonització emocional',
    'app.report.scales.self_awareness': 'Consciència de si mateix',
    'app.report.scales.meaning_quest': 'Cerca de sentit',
    'app.report.scales.religious_traditionalism': 'Tradicionalisme religiós',
    'app.report.scales.gender_traditionalism': 'Tradicionalisme de gènere',
    'app.report.scales.sociocultural_openness': 'Obertura sociocultural',
    'app.report.scales.ecological_resonance': 'Ressonància ecològica',
    'app.report.scales.global_resonance': 'Ressonància global',
    'app.report.aggregate_profile.high': 'Alt',
    'app.report.aggregate_profile.low': 'Baix',
    'app.report.aggregate_profile.average': 'Mitjà',
    'app.report.background_statistic_item.income_participants': '{0} de {1} participants - {2} %',
    'app.report.background_statistic_item.background_information': 'Informacío de Fons',
    'app.report.background_statistic_item.lowest': 'Més Baix',
    'app.report.background_statistic_item.middle': 'Mitjà',
    'app.report.background_statistic_item.highest': 'Més Alt',
    'app.report.background_statistic_item.age': 'Edat',
    'app.report.background_statistic_item.average_age': 'Edat Mitjana:',
    'app.report.background_statistic_item.answer': 'Resposta:',
    'app.report.background_statistic_item.not_answer': 'Sense Resposta:',
    'app.report.background_statistic_item.range': 'Rang:',
    'app.report.background_statistic_item.gender': 'Gènere',
    'app.report.background_statistic_item.ethnic_background': 'Origen  ètnic',
    'app.report.background_statistic_item.country_of_origin': 'País d’origen',
    'app.report.background_statistic_item.raised_in': 'Criat a',
    'app.report.background_statistic_item.not_raised_in': 'No Criat a',
    'app.report.background_statistic_item.details': 'Detalls',
    'app.report.background_statistic_item.years_of_education': 'Anys d’estudis',
    'app.report.background_statistic_item.income': 'Ingressos',
    'app.report.background_statistic_item.average': 'Mitjà:',
    'app.report.background_statistic_item.marital_status': 'Estat de Relacions',
    'app.report.background_statistic_item.religion': 'Religió',
    'app.report.background_statistic_item.interest': 'Interès',
    'app.report.background_statistic_item.satisfaction': 'Satisfacció',
    'app.report.background_statistic_item.domain_scores': 'Puntuació als dominis',
    'app.report.background_statistic_item.validity': 'Validesa',
    'app.report.background_statistic_item.average_missing_items': 'Mitjà d\'ítems que falten',
    'app.report.background_statistic_item.average_quantitative_completion_time': 'Temps de Realització Quantitativa Mitjana',
    'app.report.background_statistic_item.full_scale': 'Escala completa',
    'app.report.background_statistic_item.formative_variables': 'I. Variables formatius',
    'app.report.background_statistic_item.fulfillment_of_core_needs': 'II. Satisfacció de necessitats bàsiques ',
    'app.report.background_statistic_item.tolerance_of_disequilibrium': 'III. Tolerància al desequilibri',
    'app.report.background_statistic_item.critical_thinking': 'IV. Pensament crític',
    'app.report.background_statistic_item.self_access': 'V. Accés a si mateix',
    'app.report.background_statistic_item.other_access': 'VI. Accés a altres',
    'app.report.background_statistic_item.global_access': 'VII. Accés global',
    'app.report.decile_profile.high': 'Alt',
    'app.report.decile_profile.low': 'Baix',
    'app.report.decile_profile.average': 'Mitjà',
    'app.report.background_domain_contrast.title': 'Contrast de dominis de fons',
    'app.report.background_domain_contrast.lowest_full_scale': 'Escala Completa més Baixa',
    'app.report.background_domain_contrast.middle_full_scale': 'Escala Completa Mitjana',
    'app.report.background_domain_contrast.highest_full_scale': 'Escala Completa més Alta',
    'app.report.background_domain_contrast.mean': 'Mitjà',
    'app.report.background_domain_contrast.range': 'Rang',
    'app.report.background_domain_contrast.background_information': 'Informació de fons',
    'app.report.profile_contrast.lowest': 'Més baix',
    'app.report.profile_contrast.middle': 'Mitjà',
    'app.report.profile_contrast.highest': 'Més alt',
    'app.report.profile_contrast.lowest_optimal_profiles': 'Perfils òptims més baixos',
    'app.report.profile_contrast.middle_optimal_profiles': 'Perfils òptims mitjans',
    'app.report.profile_contrast.highest_optimal_profiles': 'Perfils òptims més alts',
    'app.report.aggregate_profile.country_of_origin.selected_country': 'País seleccionat',
    'app.report.aggregate_profile.country_of_origin.selected_countries': 'Països seleccionats',
    'app.report.aggregate_profile.religious_orientation.selected_ethnic': 'Ètnia seleccionada',
    'app.report.aggregate_profile.religious_orientation.selected_ethnics': 'Ètnies seleccionades',
    'app.report.aggregate_profile.country_of_origin.other': 'Altre',
    'app.report.aggregate_profile.religious_orientation.other': 'Altra',
    'app.report.aggregate_profile.parents_country.same': 'Mateix',
    'app.report.aggregate_profile.parents_country.different': 'Diferent',
    'app.report.aggregate_profile.relationship_status.selected_relationship_status': 'Estat de Relacions Seleccionat',
    'app.report.aggregate_profile.religion.selected_religion': 'Religió seleccionada',
    'app.report.aggregate_profile.relationship_status.other': 'Altre',
    'app.report.aggregate_profile.gender.male': 'Home',
    'app.report.aggregate_profile.gender.female': 'Dona',
    'app.report.aggregate_profile.gender.self_identify': 'Prefereixo auto-categorització',
    'app.report.aggregate_profile.education.lowest_30': '30% més baix',
    'app.report.aggregate_profile.education.highest_30': '30% més Aalt',
    'app.report.aggregate_profile.ethnicity.selected_ethnicity': 'Etnicitat seleccionada',
    'app.report.aggregate_profile.ethnicity.selected_ethnicities': 'Etnicitats seleccionades',
    'app.report.aggregate_profile.ethnicity.other': 'Altra',
    'app.report.aggregate_profile.income.bottom_third': 'Terç més baix',
    'app.report.aggregate_profile.income.middle_third': 'Terç mitjà',
    'app.report.aggregate_profile.income.top_third': 'Terç més alt',
    'app.report.aggregate_profile.interest.low': 'Baix',
    'app.report.aggregate_profile.interest.middle': 'Mitjà',
    'app.report.aggregate_profile.interest.high': 'Alt',
    'app.report.aggregate_profile.politics.low': 'Baix',
    'app.report.aggregate_profile.politics.middle': 'Mitjà',
    'app.report.aggregate_profile.politics.high': 'Alt',
    'app.report.aggregate_profile.age.low': 'Baix',
    'app.report.aggregate_profile.age.middle': 'Mitjà',
    'app.report.aggregate_profile.age.high': 'Alt',
    'app.report.aggregate_profile.religion.low': 'Baix',
    'app.report.aggregate_profile.religion.middle': 'Mitjà',
    'app.report.aggregate_profile.religion.high': 'Alt',
    'app.report.aggregate_profile.satisfaction.low': 'Baix',
    'app.report.aggregate_profile.satisfaction.middle': 'Mitjà',
    'app.report.aggregate_profile.satisfaction.high': 'Alt',
    'app.report.aggregate_profile.mother_father_education.low': 'Baix',
    'app.report.aggregate_profile.mother_father_education.middle': 'Mitjà',
    'app.report.aggregate_profile.mother_father_education.high': 'Alt',
    'app.report.aggregate_profile.time_spent_other_countries.low': 'Baix',
    'app.report.aggregate_profile.time_spent_other_countries.middle': 'Mitjà',
    'app.report.aggregate_profile.time_spent_other_countries.high': 'Alt',
    'app.report.aggregate_profile.minority_group.minority': 'Minoría',
    'app.report.aggregate_profile.minority_group.majority': 'Majoría',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work': 'Àrea de Treball Principal Seleccionat',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work': 'Àrees de Treball Principals Seleccionats',
    'app.report.aggregate_profile.primary_area_of_work.other': 'Altra',
    'app.report.aggregate_profile.years_of_language_study.low': 'Baix',
    'app.report.aggregate_profile.years_of_language_study.middle': 'Mitjà',
    'app.report.aggregate_profile.years_of_language_study.high': 'Alt',
    'app.report.aggregate_profile.academic_rank.low': 'Baix',
    'app.report.aggregate_profile.academic_rank.middle': 'Mitjà',
    'app.report.aggregate_profile.academic_rank.high': 'Alt',
    'app.report.aggregate_profile.visited_countries.low': 'Baix',
    'app.report.aggregate_profile.visited_countries.middle': 'Mitjà',
    'app.report.aggregate_profile.visited_countries.high': 'Alt',
    'app.report.aggregate_profile.fluency.low': 'Gens o mínimament',
    'app.report.aggregate_profile.fluency.middle': 'Una mica o modestament',
    'app.report.aggregate_profile.fluency.high': 'Amb bastant de fluïdesa o amb fluïdesa',
    'app.report.aggregate_demographic.core_demographics': 'Demografia bàsica',
    'app.report.aggregate_demographic.parental_background': 'Antecedents dels pares',
    'app.report.aggregate_demographic.political_religious_inclinations': 'Inclinacions polítiques i religioses',
    'app.report.aggregate_demographic.education_background': 'Formació acadèmica',
    'app.report.aggregate_demographic.international_cultural_experiences': 'Experiències culturals i internacionals',
    'app.report.aggregate_demographic.age': 'Edat',
    'app.report.aggregate_demographic.gender': 'Gènere',
    'app.report.aggregate_demographic.relationship_status': 'Estat de relació',
    'app.report.aggregate_demographic.country_origin': "País d'origen",
    'app.report.aggregate_demographic.parent_country': 'País dels pares',
    'app.report.aggregate_demographic.mother_education': 'Educació de la mare',
    'app.report.aggregate_demographic.father_education': 'Educació del pare',
    'app.report.aggregate_demographic.ethnic_background': 'Origen ètnic',
    'app.report.aggregate_demographic.minority_group': 'Grup minoritari',
    'app.report.aggregate_demographic.political_orientation': 'Orientació política',
    'app.report.aggregate_demographic.religious_orientation': 'Orientació religiosa',
    'app.report.aggregate_demographic.religious_commitment': 'Compromís religiós',
    'app.report.aggregate_demographic.income_level': "Nivell d'ingressos",
    'app.report.aggregate_demographic.years_education': "Anys d'educació",
    'app.report.aggregate_demographic.primary_area_work': 'Àrea principal de treball',
    'app.report.aggregate_demographic.academic_rank': 'Rang acadèmic',
    'app.report.aggregate_demographic.time_spent_countries': 'Temps passat en altres països',
    'app.report.aggregate_demographic.visited_countries': 'Països visitats',
    'app.report.aggregate_demographic.personal_interest': 'Interès personal',
    'app.report.aggregate_demographic.study_abroad_satisfaction': "Satisfacció amb els estudis a l'estranger",
    'app.report.aggregate_demographic.fluency': 'Fluïdesa',
    'app.report.aggregate_demographic.years_language_study': "Anys d'estudi de llengua",
    'app.report.comparative_individual_report_result_title': `Resultats de l'informe de canvi individual de BEVI`,
    'app.report.comparative_individual_report_result_description': `D'una administració a l'altra, esteu movent-vos entre diferents "bandes" de canvi al BEVI (és a dir, podeu estar mostrant un canvi substancial d'una administració a una altra). "Being Bevi" us pot dir més sobre els canvis que realment s'estan produint entre les diferents administracions de BEVI (és a dir, Being Bevi és l'entitat d'IA que viu dins del Beviverse; feu clic al cercle a l'extrem inferior dret). Si hi ha text de color a continuació, això normalment significa que les vostres puntuacions han canviat substancialment d'una administració del BEVI a la següent. El text acolorit fa referència a les direccions en què s'ha produït aquest canvi a les diferents administracions del BEVI (p. ex., normalment això seria des de la primera vegada que vau agafar el BEVI fins a la segona, però això depèn realment de quantes vegades vau prendre el BEVI). heu pres el BEVI i quines comparacions esteu fent). Si voleu aprofundir més, per veure canvis específics per a vosaltres a les 17 escales del BEVI, poseu-vos en contacte amb el vostre administrador de BEVI o poseu-vos en contacte amb l'equip BEVI a`,
    'app.report.comparative_individual_report_result_description_no_change': `D'una administració a l'altra, et quedes dins de la mateixa "banda" de canvi. "Being Bevi" us pot dir més sobre els canvis que realment s'estan produint entre una administració de BEVI a una altra (és a dir, Being Bevi és l'entitat d'IA que viu dins del Beviverse; feu clic al cercle de l'extrem inferior dret). ). Si voleu aprofundir més, per veure canvis específics per a vosaltres a les 17 escales del BEVI, poseu-vos en contacte amb el vostre administrador de BEVI o poseu-vos en contacte amb l'equip BEVI a`,
    'app.report.partner_report_result_title': "Resultats de l'informe de socis de BEVI",
    'app.report.partner_change_report_result_title': "Resultats de l'informe de canvi de socis de BEVI",
    'app.report.permission.modal_title': 'Estimat/da Participant del BEVI',
    'app.report.permission.modal_description': `
    <h5>Ja heu donat el vostre consentiment per prendre el BEVI, però abans que puguem treballar amb vosaltres en el vostre informe BEVI, us demanem que accepteu</h5>
    <br />
    <p>
    1) que vols participar en una o més sessions on es discutirà amb tu el teu informe individual i
    </p>
    <p>
    2) que esteu autoritzant <b>[#USERS#]</b> a revisar el vostre informe individual (p. ex., la vostra narració, puntuacions) i discutir el significat o les implicacions del vostre informe BEVI amb vosaltres.
    Per descomptat, tal com s'indica al formulari de consentiment per al BEVI, els vostres resultats individuals seran completament confidencials. Si teniu cap pregunta o necessiteu més informació, poseu-vos en contacte amb l'administrador de BEVI que està treballant amb vosaltres.
    </p>
    <p>
    Si enteneu i accepteu les dues disposicions anteriors, feu clic al botó "Estic d'acord" a continuació.
    </p>
    <center>Gràcies pel vostre interès en el BEVI!</center>
    `,
    'app.report.permission.pending': 'Pendent',
    'app.report.permission.approved': 'Aprovat',
    'app.report.permission.rejected': 'Rebutjat',
    'app.report.permission.approve': "ESTIC D'ACORD",
    'app.report.permission.reject': "NO ESTIC D'ACORD",
    "app.reports.selectAll": "Seleccionar Tudo",
    "app.reports.deselectAll": "Desseleccionar Tudo"
}
