import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { DecileProfileItem } from '../services/reportService';
import { forEach } from 'lodash';
import { Button } from 'antd';
import { GetFormattedMessage } from '../utils/htmlHelper';

type Props = {
    consistency: DecileProfileItem[];
    congruency: DecileProfileItem[];
    negativeLifeEvents: DecileProfileItem[];
    needsClosure: DecileProfileItem[];
    needsFulfillment: DecileProfileItem[];
    identityDiffusion: DecileProfileItem[];
    basicOpenness: DecileProfileItem[];
    selfCertitude: DecileProfileItem[];
    basicDeterminism: DecileProfileItem[];
    socioemotionalConvergence: DecileProfileItem[];
    physicalResonance: DecileProfileItem[];
    emotionalAttunement: DecileProfileItem[];
    selfAwereness: DecileProfileItem[];
    meaningQuest: DecileProfileItem[];
    religiousTraditionalism: DecileProfileItem[];
    genderTraditionalism: DecileProfileItem[];
    socioculturalOpenness: DecileProfileItem[];
    ecologicalResonance: DecileProfileItem[];
    globalResonance: DecileProfileItem[];
};

const DecileProfileLineChart: React.FC<Props> = (props: Props) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const chartInstance = useRef<echarts.ECharts | null>(null);
    const [allSelected, setAllSelected] = useState(true); // Tracks selection state

    const dataChart = useMemo(() => {
        const returnSeries: echarts.EChartOption.Series[] = [];
        const legend: string[] = [];

        forEach(props, (value, key) => {
            value.forEach((data) => {
                legend.push(data.decileTitle);
                returnSeries.push({
                    name: data.decileTitle,
                    type: 'line',
                    data: [
                        data.value1,
                        data.value2,
                        data.value3,
                        data.value4,
                        data.value5,
                        data.value6,
                        data.value7,
                        data.value8,
                        data.value9,
                        data.value10,
                    ],
                });
            });
        });

        return { series: returnSeries, dataLegend: legend };
    }, [props]);

    useEffect(() => {
        if (chartRef.current) {
            chartInstance.current = echarts.init(chartRef.current);

            const option: echarts.EChartOption = {
                grid: {
                    left: 0,
                    right: '25%',
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    left: 'right',
                    top: 'center',
                    data: dataChart.dataLegend,
                },
                xAxis: {
                    type: 'category',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false
                    }
                },
                series: dataChart.series,
            };

            chartInstance.current.setOption(option);

            return () => {
                chartInstance.current?.dispose();
            };
        }
    }, [dataChart]);

    const handleSelectAll = () => {
        if (chartInstance.current) {
            // Toggle between selecting all or deselecting all
            if (allSelected) {
                dataChart.dataLegend.forEach((legendItem) => {
                    chartInstance.current?.dispatchAction({
                        type: 'legendUnSelect',
                        name: legendItem,
                    });
                });
            } else {
                dataChart.dataLegend.forEach((legendItem) => {
                    chartInstance.current?.dispatchAction({
                        type: 'legendSelect',
                        name: legendItem,
                    });
                });
            }
            setAllSelected(!allSelected); // Update selection state
        }
    };

    return (
        <div style={{ position: 'relative', paddingTop: 60 }}>
            <div style={{ position: 'absolute', top: 20, right: 20, zIndex: 10 }}>
                <Button onClick={handleSelectAll}>
                    {allSelected ? GetFormattedMessage('app.reports.deselectAll') : GetFormattedMessage('app.reports.selectAll')}
                </Button>
            </div>
            <div
                ref={chartRef}
                style={{ width: '100%', height: `calc(100vh - 200px)` }}
            />
        </div>
    );
};

export default DecileProfileLineChart;
