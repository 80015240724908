import { arrayToObject, asLiterals } from '../utils/helpers';

export type PageMessages = AppMessages & CounterPageMessages & FinancialReportMessages &
    LoginPageMessages & ScaleQuestionsPageMessages & DiscursiveQuestionsPageMessages &
    ConsentPageMessages & ListFormPageMessages & DemographicPageMessages &
    ListOrganizationsMessages & InternalTemplateMessages & CreateProgramMessages &
    IndividualReportGenderTraditionMessages & IndividualReportMessages &
    UsersMessages & DataDownloadMessages & NoInfoMessages & CreateReportMessages &
    ReportsMessages & TimeMessages & ListReportMessages & PermissionMessages & ExternalPageMessages &
    InvitationPageMessages & InstitutionMessages & ProgramsMessages & VirtualAccountMessages;

const appMessages = asLiterals([
    'app.unknown_error',
    'app.btn_cancel',
    'app.btn_next',
    'app.btn_ok',
    'app.btn_confirm',
    'app.btn_finish',
    'app.btn_back',
    'app.btn_reset',
    'app.search_placeholder',
    'app.dropdown_select',
    'app.loading',
    'app.response_yes',
    'app.response_no',
    'app.bevi_copyright_text_1',
    'app.bevi_copyright_text_2',
    'app.btn_start',
    'app.btn_restart',
    'app.empty.data',
    'app.systemerro',
    'app.loading_document',
    'app.download_document',
    'app.btn_search',
    'app.btn_submit',
    'app.english',
    'app.catalan',
    'app.spanish',
    'app.japanese',
    'app.chinese-t',
    'app.chinese-s',
    'app.true',
    'app.false',
    'app.save',
    'app.download_excel',
    'app.notification.error_title',
    'app.error.user_not_found',
    'app.save_with_success',
    'app.swahili',
    'app.german',
    'app.hindi',
]);
export type AppMessages = { [K in (typeof appMessages)[number]]: string };
export const appMessagesIds: AppMessages = { ...arrayToObject(appMessages) };

const createProgramMessages = asLiterals([
    'app.create_program.btn_submit',
    'app.create_program.btn_submit_and_send_email',
    'app.create_program.btn_unselect_all_users',
    'app.create_program.select_individual_manually',
    'app.create_program.table.column_name',
    'app.create_program.table.column_email',
    'app.create_program.table.column_actions',
    'app.create_program.table.column_get_last_users',
    'app.create_program.tooltip.action_remove',
    'app.create_program.tooltip.btn_get_last_users',
    'app.create_program.tooltip.btn_download_users',
    'app.create_program.tooltip.btn_download_users_empty',
    'app.create_program.excel.name_column',
    'app.create_program.excel.login_column',
    'app.create_program.excel.active_column',
    'app.create_program.confirmation_title_success',
    'app.create_program.confirmation_body_success_program',
    'app.create_program.confirmation_body_success_subprogram',
    'app.create_program.confirmation_title_fail',
    'app.create_program.confirmation_body_fail_program',
    'app.create_program.confirmation_body_fail_subprogram',
    'app.edit_sub_program.confirmation_title_success',
    'app.edit_sub_program.confirmation_title_fail',
    'app.edit_sub_program.confirmation_body_success',
    'app.edit_sub_program.confirmation_body_fail',
    'app.details_sub_program.progress',
    'app.details_sub_program.participant_details',
    'app.details_sub_program.remind_alert',
    'app.details_sub_program.remind_alert_text',
    'app.create_program.lbl_associate_users',
    'app.create_program.lbl_organization_name',
    'app.create_program.lbl_program_name',
    'app.create_program.lbl_sub_program_name',
    'app.create_program.lbl_year',
    'app.create_program.lbl_due_date',
    'app.create_program.lbl_alert_all_users',
    'app.create_program.lbl_email_message',
    'app.create_program.tooltip.btn_upload_users',
    'app.create_program.upload_files_error_title',
    'app.create_program.upload_rejected_files_error_body_prefix',
    'app.create_program.upload_parsed_files_error_body_prefix',
    'app.create_program.upload_parsed_files_error_body',
    'app.create_program.upload_parsed_files_unknow_error_body_prefix',
    'app.create_program.select_individuals_title',
    'app.create_program.import_individuals_from_previous_administration',
    'app.create_program.download_manage_who_will_take_the_bevi',
    'app.create_program.upload_who_will_take_the_bevi',
    'app.create_program.add_individuals_manually',
]);
export type CreateProgramMessages = { [K in (typeof createProgramMessages)[number]]: string };
export const createProgramMessageIds: CreateProgramMessages = { ...arrayToObject(createProgramMessages) };

const counterMessages = asLiterals(['app.counter.counter', 'app.counter.double.counter']);
export type CounterPageMessages = { [K in (typeof counterMessages)[number]]: string };
export const counterMessagesIds: CounterPageMessages = { ...arrayToObject(counterMessages) };

const loginMessages = asLiterals([
    'app.login.text_welcome',
    'app.login.text_information',
    'app.login.text_login_requirement',
    'app.login.lbl_username',
    'app.login.lbl_password',
    'app.login.btn_submit',
    'app.login.lbl_new_password',
    'app.login.lbl_new_password_confirmation',
    'app.login.btn_submit_new_password',
    'app.login.placeholder.login',
    'app.login.placeholder.password',
    'app.login.first_access.title',
    'app.login.invalid_credentials',
    'app.login.forgot_password.invalid_credentials',
    'app.login.forgot_password',
    'app.login.forgot_password_confirmation_title',
    'app.login.forgot_password_confirmation_body',
    'app.login.btn_submit_forgot_password',
    'app.login.passwords_dont_match',
    'app.login.change_password.expired_confirmation_title',
    'app.login.change_password.expired_confirmation_body',
    'app.login.change_password.success_confirmation_title',
    'app.login.change_password.success_confirmation_body',
    'app.login.auth.qrcode',
    'app.login.auth.type',
    'app.login.auth.backLogin',
    'app.login.auth.invalid_code',
    'app.login.auth.validate_success'
]);
export type LoginPageMessages = { [K in (typeof loginMessages)[number]]: string };
export const loginMessageIds: LoginPageMessages = { ...arrayToObject(loginMessages) };

const externalMessages = asLiterals([
    'app.external.title.thankyou',
    'app.external.thankyou_static_1_1',
    'app.external.thankyou_static_1_2',
    'app.external.thankyou_static_2',
    'app.external.thankyou_static_3',
]);
export type ExternalPageMessages = { [K in (typeof externalMessages)[number]]: string };
export const externalMessageIds: ExternalPageMessages = { ...arrayToObject(externalMessages) };

const invitationMessages = asLiterals([
    'app.invitation.invite_label_template',
    'app.invitation.name',
    'app.invitation.language',
    'app.invitation.email',
    'app.invitation.password',
    'app.invitation.confirm_password',
    'app.invitation.blank_feedback',
    'app.invitation.invalid_email_feedback',
    'app.invitation.password_length_feedback_template',
    'app.invitation.confirm_password_not_equal_feedback',
    'app.invitation.success_notification_header',
    'app.invitation.success_notification_body',
    'app.invitation.error_notification_header',
    'app.invitation.error_notification_body',
]);
export type InvitationPageMessages = { [K in (typeof invitationMessages)[number]]: string };
export const invitationMessageIds: InvitationPageMessages = { ...arrayToObject(invitationMessages) };

const consentMessages = asLiterals([
    'app.consent.title', 'app.consent.form_title', 'app.consent.greeting', 'app.consent.introduction',
    'app.consent.general.observation', 'app.consent.explanation_1', 'app.consent.explanation_2',
    'app.consent.participant.rights.1', 'app.consent.participant.rights.2',
    'app.consent.understand.agree', 'app.consent.have.questions',
    'app.consent.thanks', 'app.consent.giving.consent',
    'app.consent.read.and.agree', 'app.consent.agree', 'app.consent.disagree',
    'app.custom_agreement.modal_title',
    'app.custom_agreement.modal_explanation_text',
    'app.custom_agreement.default_custom_agreement',
    'app.custom_agreement.partner_report_agreement'
]);
export type ConsentPageMessages = { [K in (typeof consentMessages)[number]]: string };
export const consentMessagesIds: CounterPageMessages = { ...arrayToObject(consentMessages) };

const discursiveQuestionsMessages = asLiterals([
    'app.discursive_questions.introduction',
    'app.discursive_questions.question1',
    'app.discursive_questions.question2',
    'app.discursive_questions.question3',
    'app.discursive_questions.thank_you',
    'app.discursive_questions.please_note',
    'app.discursive_questions.go_to_report',
    'app.discursive_questions.do_not_show_report',
    'app.discursive_questions.visualize_report',
]);
export type DiscursiveQuestionsPageMessages = { [K in (typeof discursiveQuestionsMessages)[number]]: string };
export const discursiveQuestionsMessagesIds: DiscursiveQuestionsPageMessages = { ...arrayToObject(consentMessages) };


const listFormsMessages = asLiterals([
    'app.listforms.title', 'app.listforms.subtitle', 'app.listforms.participant', 'app.listforms.organization', 'app.listforms.organization_tooltip', 'app.listforms.program',
    'app.listforms.program_tooltip', 'app.listforms.take_by', 'app.listforms.take_by_tooltip',
    'app.listforms.completed_on', 'app.listforms.completed_on_tooltip', 'app.listforms.progress',
    'app.listforms.progress_tooltip', 'app.listforms.status', 'app.listforms.status_tooltip',
    'app.listforms.custom_agreement_status', 'app.listforms.action', 'app.listforms.action_tooltip', 'app.listforms.all', 'app.listforms.available',
    'app.listforms.onDemographics', 'app.listforms.onScales', 'app.listforms.onSubjective',
    'app.listforms.finished', 'app.listforms.cancelled',
    'app.listforms.finished', 'app.listforms.cancelled', 'app.listforms.expired',
    'app.listforms.archived', 'app.listforms.invalid',
    'app.listforms.start', 'app.listforms.continue',
    'app.listforms.report', 'app.listforms.restart', 'app.listforms.confirmation_title', 'app.listforms.retart_confirmation_title',
    'app.listforms.restart.instruction.part1', 'app.listforms.instruction.part1', 'app.listforms.instruction.part2',
    'app.listforms.instruction.part3', 'app.listforms.instruction.press.start', 'app.listforms.comparative_individual_report',
    'app.listforms.partner_report', 'app.listforms.partner_change_report',
    'app.form.status_title', 'app.form.status_finished_subtitle', 'app.form.status_finished_body',
    'app.form.status_cancelled_subtitle', 'app.form.status_cancelled_body',
    'app.form.status_expired_subtitle', 'app.form.status_expired_body',
    'app.form.invalid_status.by_time',
    'app.form.invalid_status.by_number_of_questions',
    'app.form.invalid_status.by_consecutive_answers',
    'app.form.invalid_status.by_common_answers',
    'app.form.invalid_status.by_congruency',
    'app.form.invalid_status.by_consistency',
    'app.form.invalid_status.by_wrong_scale_value',
    'app.form.custom_agreement.not_requested',
    'app.form.custom_agreement.pending',
    'app.form.custom_agreement.accepted',
    'app.form.custom_agreement.rejected',
    'app.form.actions.tooltip.send_custom_agreement',
    'app.form.actions.tooltip.resend_custom_agreement',
    'app.form.actions.tooltip.show_report',
    'app.listforms.ai_interactions',
]);
export type ListFormPageMessages = { [K in (typeof listFormsMessages)[number]]: string };
export const listFormsMessageIds: ListFormPageMessages = { ...arrayToObject(listFormsMessages) };

const scaleQuestionsConfirmation = asLiterals([
    'app.questions.confirmation_title', 'app.questions.confirmation'
]);
export type ScaleQuestionsConfirmationMessages =
    { [K in (typeof scaleQuestionsConfirmation)[number]]: string };

const scaleQuestions = asLiterals([
    'app.question.strongly_agree', 'app.question.agree', 'app.question.disagree', 'app.question.strongly_disagree',
    'app.question.q1', 'app.question.q2', 'app.question.q3', 'app.question.q4', 'app.question.q5',
    'app.question.q6', 'app.question.q7', 'app.question.q8', 'app.question.q9', 'app.question.q10',
    'app.question.q11', 'app.question.q12', 'app.question.q13', 'app.question.q14', 'app.question.q15',
    'app.question.q16', 'app.question.q17', 'app.question.q18', 'app.question.q19', 'app.question.q20',
    'app.question.q21', 'app.question.q22', 'app.question.q23', 'app.question.q24', 'app.question.q25',
    'app.question.q26', 'app.question.q27', 'app.question.q28', 'app.question.q29', 'app.question.q30',
    'app.question.q31', 'app.question.q32', 'app.question.q33', 'app.question.q34', 'app.question.q35',
    'app.question.q36', 'app.question.q37', 'app.question.q38', 'app.question.q39', 'app.question.q40',
    'app.question.q41', 'app.question.q42', 'app.question.q43', 'app.question.q44', 'app.question.q45',
    'app.question.q46', 'app.question.q47', 'app.question.q48', 'app.question.q49', 'app.question.q50',
    'app.question.q51', 'app.question.q52', 'app.question.q53', 'app.question.q54', 'app.question.q55',
    'app.question.q56', 'app.question.q57', 'app.question.q58', 'app.question.q59', 'app.question.q60',
    'app.question.q61', 'app.question.q62', 'app.question.q63', 'app.question.q64', 'app.question.q65',
    'app.question.q66', 'app.question.q67', 'app.question.q68', 'app.question.q69', 'app.question.q70',
    'app.question.q71', 'app.question.q72', 'app.question.q73', 'app.question.q74', 'app.question.q75',
    'app.question.q76', 'app.question.q77', 'app.question.q78', 'app.question.q79', 'app.question.q80',
    'app.question.q81', 'app.question.q82', 'app.question.q83', 'app.question.q84', 'app.question.q85',
    'app.question.q86', 'app.question.q87', 'app.question.q88', 'app.question.q89', 'app.question.q90',
    'app.question.q91', 'app.question.q92', 'app.question.q93', 'app.question.q94', 'app.question.q95',
    'app.question.q96', 'app.question.q97', 'app.question.q98', 'app.question.q99', 'app.question.q100',
    'app.question.q101', 'app.question.q102', 'app.question.q103', 'app.question.q104', 'app.question.q105',
    'app.question.q106', 'app.question.q107', 'app.question.q108', 'app.question.q109', 'app.question.q110',
    'app.question.q111', 'app.question.q112', 'app.question.q113', 'app.question.q114', 'app.question.q115',
    'app.question.q116', 'app.question.q117', 'app.question.q118', 'app.question.q119', 'app.question.q120',
    'app.question.q121', 'app.question.q122', 'app.question.q123', 'app.question.q124', 'app.question.q125',
    'app.question.q126', 'app.question.q127', 'app.question.q128', 'app.question.q129', 'app.question.q130',
    'app.question.q131', 'app.question.q132', 'app.question.q133', 'app.question.q134', 'app.question.q135',
    'app.question.q136', 'app.question.q137', 'app.question.q138', 'app.question.q139', 'app.question.q140',
    'app.question.q141', 'app.question.q142', 'app.question.q143', 'app.question.q144', 'app.question.q145',
    'app.question.q146', 'app.question.q147', 'app.question.q148', 'app.question.q149', 'app.question.q150',
    'app.question.q151', 'app.question.q152', 'app.question.q153', 'app.question.q154', 'app.question.q155',
    'app.question.q156', 'app.question.q157', 'app.question.q158', 'app.question.q159', 'app.question.q160',
    'app.question.q161', 'app.question.q162', 'app.question.q163', 'app.question.q164', 'app.question.q165',
    'app.question.q166', 'app.question.q167', 'app.question.q168', 'app.question.q169', 'app.question.q170',
    'app.question.q171', 'app.question.q172', 'app.question.q173', 'app.question.q174', 'app.question.q175',
    'app.question.q176', 'app.question.q177', 'app.question.q178', 'app.question.q179', 'app.question.q180',
    'app.question.q181', 'app.question.q182', 'app.question.q183', 'app.question.q184', 'app.question.q185',
]);
export type ScaleQuestionsMessages = { [K in (typeof scaleQuestions)[number]]: string };

const scaleQuestionsPage = [...scaleQuestionsConfirmation, ...scaleQuestions];
export type ScaleQuestionsPageMessages = { [K in (typeof scaleQuestionsPage)[number]]: string };
export const scaleQuestionsMessageIds: ScaleQuestionsPageMessages =
    { ...arrayToObject(scaleQuestionsPage) };

const demographicQuestionsMessages = asLiterals([
    'app.demographic.age',
    'app.demographic.gender',
    'app.demographic.marital_status',
    'app.demographic.level_of_fluency',
    'app.demographic.education_years',
    'app.demographic.raised_in_country_where_currently_in',
    'app.demographic.ethnic',
    'app.demographic.ethnic_other',
    'app.demographic.ethnic_asian',
    'app.demographic.ethnic_african',
    'app.demographic.ethnic_latino',
    'app.demographic.ethnic_indigenous',
    'app.demographic.ethnic_middle_eastern',
    'app.demographic.ethnic_white',
    'app.demographic.completing_bevi',
    'app.demographic.completing_bevi_other',
    'app.demographic.previously_completed',
    'app.demographic.disadvantaged_or_minority_group',
    'app.demographic.disadvantaged_or_minority_group_yes',
    'app.demographic.primary_area_of_work',
    'app.demographic.current_area',
    'app.demographic.political_orientation',
    'app.demographic.political_commitment',
    'app.demographic.political_orientation_conservative_label',
    'app.demographic.political_orientation_liberal_label',
    'app.demographic.religion',
    'app.demographic.religion_other',
    'app.demographic.religion_scale',
    'app.demographic.religion_scale_specified',
    'app.demographic.income_level',
    'app.demographic.income_less_label',
    'app.demographic.income_more_label',
    'app.demographic.academic_rank',
    'app.demographic.academic_rank_lowest_label',
    'app.demographic.academic_rank_highest_label',
    'app.demographic.primary_country',
    'app.demographic.time_in_other_countries',
    'app.demographic.time_in_other_countries_other',
    'app.demographic.visit_countries',
    'app.demographic.country_explanation_field',
    'app.demographic.country_mother_born',
    'app.demographic.country_father_born',
    'app.demographic.personal_interest',
    'app.demographic.participated_experiences',
    'app.demographic.participated_learning_courses',
    'app.demographic.plan_participate_experiences',
    'app.demographic.plan_participate_learning_courses',
    'app.demographic.experience_satisfaction',
    'app.demographic.languages_speak',
    'app.demographic.years_studied_foreign_language',
    'app.demographic.primary_language',
    'app.demographic.primary_language_other',
    'app.demographic.language_level_of_fluency',
    'app.demographic.language_level_of_fluency_add_anwser',
    'app.demographic.table_level_of_fluency',
    'app.demographic.table_language',
    'app.demographic.table_action',
    'app.demographic.remove_language_tooltip',
    'app.demographic.father_formal_education',
    'app.demographic.mother_formal_education',
    'app.demographic.comparative',
    'app.demographic.compare',
    'app.demographic.select_multiple_reports',

]);
export type DemographicQuestionsMessages = { [K in (typeof demographicQuestionsMessages)[number]]: string };
export const demographicQuestionIds: DemographicQuestionsMessages = {
    ...arrayToObject(demographicQuestionsMessages)
};

const demographicOptionMessages = asLiterals([
    'app.demographic.language_options.Afrikaans',
    'app.demographic.language_options.Albanian',
    'app.demographic.language_options.Amharic',
    'app.demographic.language_options.Arabic',
    'app.demographic.language_options.Armenian',
    'app.demographic.language_options.Basque',
    'app.demographic.language_options.Bengali',
    'app.demographic.language_options.Byelorussian',
    'app.demographic.language_options.Burmese',
    'app.demographic.language_options.Bulgarian',
    'app.demographic.language_options.Catalan',
    'app.demographic.language_options.Czech',
    'app.demographic.language_options.Chinese',
    'app.demographic.language_options.Croatian',
    'app.demographic.language_options.Danish',
    'app.demographic.language_options.Dari',
    'app.demographic.language_options.Dzongkha',
    'app.demographic.language_options.Dutch',
    'app.demographic.language_options.English',
    'app.demographic.language_options.Esperanto',
    'app.demographic.language_options.Estonian',
    'app.demographic.language_options.Faroese',
    'app.demographic.language_options.Farsi',
    'app.demographic.language_options.Finnish',
    'app.demographic.language_options.French',
    'app.demographic.language_options.Gaelic',
    'app.demographic.language_options.Galician',
    'app.demographic.language_options.German',
    'app.demographic.language_options.Greek',
    'app.demographic.language_options.Hebrew',
    'app.demographic.language_options.Hindi',
    'app.demographic.language_options.Hungarian',
    'app.demographic.language_options.Icelandic',
    'app.demographic.language_options.Indonesian',
    'app.demographic.language_options.Inuktitut_Eskimo',
    'app.demographic.language_options.Italian',
    'app.demographic.language_options.Japanese',
    'app.demographic.language_options.Khmer',
    'app.demographic.language_options.Korean',
    'app.demographic.language_options.Kurdish',
    'app.demographic.language_options.Laotian',
    'app.demographic.language_options.Latvian',
    'app.demographic.language_options.Lappish',
    'app.demographic.language_options.Lithuanian',
    'app.demographic.language_options.Macedonian',
    'app.demographic.language_options.Malay',
    'app.demographic.language_options.Maltese',
    'app.demographic.language_options.Nepali',
    'app.demographic.language_options.Norwegian',
    'app.demographic.language_options.Pashto',
    'app.demographic.language_options.Polish',
    'app.demographic.language_options.Portuguese',
    'app.demographic.language_options.Romanian',
    'app.demographic.language_options.Russian',
    'app.demographic.language_options.Scots',
    'app.demographic.language_options.Serbian',
    'app.demographic.language_options.Slovak',
    'app.demographic.language_options.Slovenian',
    'app.demographic.language_options.Somali',
    'app.demographic.language_options.Spanish',
    'app.demographic.language_options.Swedish',
    'app.demographic.language_options.Swahili',
    'app.demographic.language_options.Tagalog-Filipino',
    'app.demographic.language_options.Tajik',
    'app.demographic.language_options.Tamil',
    'app.demographic.language_options.Thai',
    'app.demographic.language_options.Tibetan',
    'app.demographic.language_options.Tigrinya',
    'app.demographic.language_options.Tongan',
    'app.demographic.language_options.Turkish',
    'app.demographic.language_options.Turkmen',
    'app.demographic.language_options.Ucrainian',
    'app.demographic.language_options.Urdu',
    'app.demographic.language_options.Uzbek',
    'app.demographic.language_options.Vietnamese',
    'app.demographic.language_options.Welsh',
    'app.demographic.language_options.Other',
    'app.demographic.gender.male',
    'app.demographic.gender.female',
    'app.demographic.gender.self_identify',
    'app.demographic.marital.response_single',
    'app.demographic.marital.response_married',
    'app.demographic.marital.response_divorced',
    'app.demographic.marital.response_widowed',
    'app.demographic.marital.response_other',
    'app.demographic.education_years.response_1',
    'app.demographic.education_years.response_2',
    'app.demographic.education_years.response_3',
    'app.demographic.education_years.response_4',
    'app.demographic.education_years.response_5',
    'app.demographic.education_years.response_6',
    'app.demographic.education_years.response_7',
    'app.demographic.education_years.response_8',
    'app.demographic.education_years.response_9',
    'app.demographic.education_years.response_10',
    'app.demographic.education_years.response_11',
    'app.demographic.education_years.response_12',
    'app.demographic.education_years.response_13',
    'app.demographic.education_years.response_14',
    'app.demographic.education_years.response_15',
    'app.demographic.education_years.response_16',
    'app.demographic.education_years.response_17',
    'app.demographic.education_years.response_18',
    'app.demographic.education_years.response_19',
    'app.demographic.education_years.response_20',
    'app.demographic.education_years.response_21',
    'app.demographic.education_years.response_22',
    'app.demographic.education_years.response_23',
    'app.demographic.education_years.response_24',
    'app.demographic.education_years.response_25',
    'app.demographic.education_years.response_26',
    'app.demographic.education_years.response_27',
    'app.demographic.education_years.response_28',
    'app.demographic.education_years.response_29',
    'app.demographic.education_years.response_30',
    'app.demographic.education_years.response_31',
    'app.demographic.education_years.response_32',
    'app.demographic.ethnic.respose_asian',
    'app.demographic.ethnic.respose_african',
    'app.demographic.ethnic.respose_latino',
    'app.demographic.ethnic.respose_indigenous',
    'app.demographic.ethnic.respose_middle_eastern',
    'app.demographic.ethnic.respose_white',
    'app.demographic.ethnic.respose_mixed',
    'app.demographic.ethnic.respose_other',
    'app.demographic.completing_bevi.response_before',
    'app.demographic.completing_bevi.response_during',
    'app.demographic.completing_bevi.response_after',
    'app.demographic.completing_bevi.response_during_academic',
    'app.demographic.completing_bevi.response_end_graduation',
    'app.demographic.completing_bevi.response_other',
    'app.demographic.primary_area_of_work.response_1',
    'app.demographic.primary_area_of_work.response_2',
    'app.demographic.primary_area_of_work.response_3',
    'app.demographic.primary_area_of_work.response_4',
    'app.demographic.primary_area_of_work.response_5',
    'app.demographic.primary_area_of_work.response_6',
    'app.demographic.primary_area_of_work.response_7',
    'app.demographic.political_orientation.response_1',
    'app.demographic.political_orientation.response_2',
    'app.demographic.political_orientation.response_3',
    'app.demographic.political_orientation.response_4',
    'app.demographic.political_orientation.response_5',
    'app.demographic.political_orientation.response_6',
    'app.demographic.religion.response_1',  //african
    'app.demographic.religion.response_2',  //agnosticism
    'app.demographic.religion.response_3',  //atheism
    'app.demographic.religion.response_4',  //bahai
    'app.demographic.religion.response_5',  //buddhism
    'app.demographic.religion.response_6',  //caodaism
    'app.demographic.religion.response_7',  //chinese
    'app.demographic.religion.response_8', //christianity
    'app.demographic.religion.response_9',  //hinduism
    'app.demographic.religion.response_10',  //indigenous
    'app.demographic.religion.response_11',  //islam
    'app.demographic.religion.response_12',  //jainism
    'app.demographic.religion.response_13',  //juche
    'app.demographic.religion.response_14',  //judaism
    'app.demographic.religion.response_15',  //paganism
    'app.demographic.religion.response_16',  //shinto
    'app.demographic.religion.response_17',  //sikhism
    'app.demographic.religion.response_18',  //spiritism
    'app.demographic.religion.response_19',  //spiritism_no_specific
    'app.demographic.religion.response_20',  //tenrikyo
    'app.demographic.religion.response_21',  //unitarian_universalism
    'app.demographic.religion.response_22',  //zoroastrianism
    'app.demographic.religion.response_other',
    'app.demographic.religion.no_response',
    'app.demographic.income_level.response_1',
    'app.demographic.income_level.response_2',
    'app.demographic.income_level.response_3',
    'app.demographic.academic_rank.response_1',
    'app.demographic.academic_rank.response_2',
    'app.demographic.academic_rank.response_3',
    'app.demographic.participated_experiences.response_1',
    'app.demographic.participated_experiences.response_2',
    'app.demographic.participated_experiences.response_3',
    'app.demographic.participated_experiences.response_4',
    'app.demographic.participated_experiences.response_5',
    'app.demographic.participated_experiences.response_6',
    'app.demographic.participated_experiences.response_7',
    'app.demographic.participated_experiences.response_8',
    'app.demographic.participated_experiences.response_9',
    'app.demographic.participated_experiences.response_10',
    'app.demographic.participated_experiences.response_11',
    'app.demographic.participated_experiences.response_12',
    'app.demographic.participated_experiences.response_13',
    'app.demographic.participated_learning_courses.response_1',
    'app.demographic.participated_learning_courses.response_2',
    'app.demographic.participated_learning_courses.response_3',
    'app.demographic.participated_learning_courses.response_4',
    'app.demographic.participated_learning_courses.response_5',
    'app.demographic.participated_learning_courses.response_6',
    'app.demographic.participated_learning_courses.response_7',
    'app.demographic.participated_learning_courses.response_8',
    'app.demographic.participated_learning_courses.response_9',
    'app.demographic.plan_participate_experiences.response_1',
    'app.demographic.plan_participate_experiences.response_2',
    'app.demographic.plan_participate_experiences.response_3',
    'app.demographic.plan_participate_experiences.response_4',
    'app.demographic.plan_participate_experiences.response_5',
    'app.demographic.plan_participate_experiences.response_6',
    'app.demographic.plan_participate_experiences.response_7',
    'app.demographic.plan_participate_experiences.response_8',
    'app.demographic.plan_participate_experiences.response_9',
    'app.demographic.plan_participate_experiences.response_10',
    'app.demographic.plan_participate_experiences.response_11',
    'app.demographic.plan_participate_experiences.response_12',
    'app.demographic.plan_participate_experiences.response_13',
    'app.demographic.plan_participate_learning_courses.response_1',
    'app.demographic.plan_participate_learning_courses.response_2',
    'app.demographic.plan_participate_learning_courses.response_3',
    'app.demographic.plan_participate_learning_courses.response_4',
    'app.demographic.plan_participate_learning_courses.response_5',
    'app.demographic.plan_participate_learning_courses.response_6',
    'app.demographic.plan_participate_learning_courses.response_7',
    'app.demographic.plan_participate_learning_courses.response_8',
    'app.demographic.plan_participate_learning_courses.response_9',
    'app.demographic.time_in_other_country.response_not_spent',
    'app.demographic.time_in_other_country.response_less_2_weeks',
    'app.demographic.time_in_other_country.response_less_1_month',
    'app.demographic.time_in_other_country.response_less_3_months',
    'app.demographic.time_in_other_country.response_less_6_months',
    'app.demographic.time_in_other_country.response_less_1_year',
    'app.demographic.time_in_other_country.response_less_2_years',
    'app.demographic.time_in_other_country.response_less_5_years',
    'app.demographic.time_in_other_country.response_less_10_years',
    'app.demographic.time_in_other_country.response_10_or_more',
    'app.demographic.country.response_eua',
    'app.demographic.country.response_afganistan',
    'app.demographic.country.response_albania',
    'app.demographic.country.response_algeria',
    'app.demographic.country.response_american_samoa',
    'app.demographic.country.response_andorra',
    'app.demographic.country.response_angola',
    'app.demographic.country.response_anguilla',
    'app.demographic.country.response_antigua_barbuda',
    'app.demographic.country.response_argentia',
    'app.demographic.country.response_armenia',
    'app.demographic.country.response_aruba',
    'app.demographic.country.response_australia',
    'app.demographic.country.response_austria',
    'app.demographic.country.response_azerbaijan',
    'app.demographic.country.response_bahamas',
    'app.demographic.country.response_bahrain',
    'app.demographic.country.response_bangladesh',
    'app.demographic.country.response_barbados',
    'app.demographic.country.response_belarus',
    'app.demographic.country.response_belgium',
    'app.demographic.country.response_belize',
    'app.demographic.country.response_benin',
    'app.demographic.country.response_bermuda',
    'app.demographic.country.response_bhutan',
    'app.demographic.country.response_bolivia',
    'app.demographic.country.response_bosnia_herzegovina',
    'app.demographic.country.response_botswana',
    'app.demographic.country.response_brazil',
    'app.demographic.country.response_brunei',
    'app.demographic.country.response_bulgaria',
    'app.demographic.country.response_burkina_faso',
    'app.demographic.country.response_burundi',
    'app.demographic.country.response_cambodia',
    'app.demographic.country.response_cameroon',
    'app.demographic.country.response_canada',
    'app.demographic.country.response_cape_verde',
    'app.demographic.country.response_cayman_islands',
    'app.demographic.country.response_central_african_republic',
    'app.demographic.country.response_chad',
    'app.demographic.country.response_chile',
    'app.demographic.country.response_china',
    'app.demographic.country.response_china_hong_kong_sar',
    'app.demographic.country.response_colombia',
    'app.demographic.country.response_comoros',
    'app.demographic.country.response_congo',
    'app.demographic.country.response_congo,_democratic_republic',
    'app.demographic.country.response_cook_islands',
    'app.demographic.country.response_costa_rica',
    'app.demographic.country.response_cote_divoire',
    'app.demographic.country.response_croatia',
    'app.demographic.country.response_cuba',
    'app.demographic.country.response_cyprus',
    'app.demographic.country.response_czech_republic',
    'app.demographic.country.response_denmark',
    'app.demographic.country.response_djibouti',
    'app.demographic.country.response_dominica',
    'app.demographic.country.response_dominican_republic',
    'app.demographic.country.response_ecuador',
    'app.demographic.country.response_egypt',
    'app.demographic.country.response_el_salvador',
    'app.demographic.country.response_equatorial_guinea',
    'app.demographic.country.response_eritrea',
    'app.demographic.country.response_estonia',
    'app.demographic.country.response_ethiopia',
    'app.demographic.country.response_euro_area',
    'app.demographic.country.response_falkland_islands',
    'app.demographic.country.response_fiji',
    'app.demographic.country.response_finland',
    'app.demographic.country.response_france',
    'app.demographic.country.response_french_guiana',
    'app.demographic.country.response_french_polynesia',
    'app.demographic.country.response_gabon',
    'app.demographic.country.response_gambia',
    'app.demographic.country.response_georgia',
    'app.demographic.country.response_germany',
    'app.demographic.country.response_ghana',
    'app.demographic.country.response_gibraltar',
    'app.demographic.country.response_greece',
    'app.demographic.country.response_grenada',
    'app.demographic.country.response_guadeloupe',
    'app.demographic.country.response_guam',
    'app.demographic.country.response_guatemala',
    'app.demographic.country.response_guernsey',
    'app.demographic.country.response_guinea',
    'app.demographic.country.response_guinea_bissau',
    'app.demographic.country.response_guyana',
    'app.demographic.country.response_haiti',
    'app.demographic.country.response_honduras',
    'app.demographic.country.response_hungary',
    'app.demographic.country.response_iceland',
    'app.demographic.country.response_india',
    'app.demographic.country.response_indonesia',
    'app.demographic.country.response_iran',
    'app.demographic.country.response_iraq',
    'app.demographic.country.response_ireland',
    'app.demographic.country.response_isle_of_man',
    'app.demographic.country.response_israel',
    'app.demographic.country.response_italy',
    'app.demographic.country.response_jamaica',
    'app.demographic.country.response_japan',
    'app.demographic.country.response_jersey',
    'app.demographic.country.response_jordan',
    'app.demographic.country.response_kazakhstan',
    'app.demographic.country.response_kenya',
    'app.demographic.country.response_kiribati',
    'app.demographic.country.response_kosovo',
    'app.demographic.country.response_kuwait',
    'app.demographic.country.response_kyrgyzstan',
    'app.demographic.country.response_laos',
    'app.demographic.country.response_latvia',
    'app.demographic.country.response_lebanon',
    'app.demographic.country.response_lesotho',
    'app.demographic.country.response_liberia',
    'app.demographic.country.response_libya',
    'app.demographic.country.response_liechtenstein',
    'app.demographic.country.response_lithuania',
    'app.demographic.country.response_luxembourg',
    'app.demographic.country.response_macau',
    'app.demographic.country.response_macedonia',
    'app.demographic.country.response_madagascar',
    'app.demographic.country.response_malaysia',
    'app.demographic.country.response_malawi',
    'app.demographic.country.response_maldives',
    'app.demographic.country.response_mali',
    'app.demographic.country.response_malta',
    'app.demographic.country.response_mariana',
    'app.demographic.country.response_marshall_islands',
    'app.demographic.country.response_martinique',
    'app.demographic.country.response_mauritania',
    'app.demographic.country.response_mauritius',
    'app.demographic.country.response_mexico',
    'app.demographic.country.response_micronesia',
    'app.demographic.country.response_moldova',
    'app.demographic.country.response_monaco',
    'app.demographic.country.response_mongolia',
    'app.demographic.country.response_montserrat',
    'app.demographic.country.response_montenegro',
    'app.demographic.country.response_morocco',
    'app.demographic.country.response_mozambique',
    'app.demographic.country.response_myanmar',
    'app.demographic.country.response_namibia',
    'app.demographic.country.response_nepal',
    'app.demographic.country.response_netherland_antilles',
    'app.demographic.country.response_netherlands',
    'app.demographic.country.response_new_caledonia',
    'app.demographic.country.response_new_zealand',
    'app.demographic.country.response_nicaragua',
    'app.demographic.country.response_niger',
    'app.demographic.country.response_nigeria',
    'app.demographic.country.response_north_korea',
    'app.demographic.country.response_norway',
    'app.demographic.country.response_oman',
    'app.demographic.country.response_pakistan',
    'app.demographic.country.response_palau',
    'app.demographic.country.response_panama',
    'app.demographic.country.response_papua_new_guinea',
    'app.demographic.country.response_paraguay',
    'app.demographic.country.response_peru',
    'app.demographic.country.response_phillipines',
    'app.demographic.country.response_poland',
    'app.demographic.country.response_portugal',
    'app.demographic.country.response_puerto_rico',
    'app.demographic.country.response_qatar',
    'app.demographic.country.response_reunion',
    'app.demographic.country.response_romania',
    'app.demographic.country.response_russian_federation',
    'app.demographic.country.response_rwanda',
    'app.demographic.country.response_st_helena',
    'app.demographic.country.response_st_kitts-nevis',
    'app.demographic.country.response_st_lucia',
    'app.demographic.country.response_st_vincent_grenadines',
    'app.demographic.country.response_stateless',
    'app.demographic.country.response_samoa',
    'app.demographic.country.response_san_marino',
    'app.demographic.country.response_sao_tome_principe',
    'app.demographic.country.response_saudi_arabia',
    'app.demographic.country.response_senegal',
    'app.demographic.country.response_seychelles',
    'app.demographic.country.response_serbia',
    'app.demographic.country.response_sierra_leone',
    'app.demographic.country.response_singapore',
    'app.demographic.country.response_slovak_republic',
    'app.demographic.country.response_slovenia',
    'app.demographic.country.response_solomon_islands',
    'app.demographic.country.response_somalia',
    'app.demographic.country.response_south_africa',
    'app.demographic.country.response_south_korea',
    'app.demographic.country.response_spain',
    'app.demographic.country.response_sri_lanka',
    'app.demographic.country.response_sudan',
    'app.demographic.country.response_suriname',
    'app.demographic.country.response_swaziland',
    'app.demographic.country.response_sweden',
    'app.demographic.country.response_switzerland',
    'app.demographic.country.response_syria',
    'app.demographic.country.response_taiwan',
    'app.demographic.country.response_tajikistan',
    'app.demographic.country.response_tanzania',
    'app.demographic.country.response_thailand',
    'app.demographic.country.response_togo',
    'app.demographic.country.response_timor_leste',
    'app.demographic.country.response_tonga',
    'app.demographic.country.response_trinidad_tobago',
    'app.demographic.country.response_tunisia',
    'app.demographic.country.response_turkey',
    'app.demographic.country.response_turkmenistan',
    'app.demographic.country.response_turks_caicos_is',
    'app.demographic.country.response_tuvalu',
    'app.demographic.country.response_uganda',
    'app.demographic.country.response_ukraine',
    'app.demographic.country.response_united_arab_erimates',
    'app.demographic.country.response_united_kingdom',
    'app.demographic.country.response_uraguay',
    'app.demographic.country.response_uzbekistan',
    'app.demographic.country.response_vanuatu',
    'app.demographic.country.response_venezuela',
    'app.demographic.country.response_vietnam',
    'app.demographic.country.response_virgin_islands',
    'app.demographic.country.response_west_bank_and_gaza',
    'app.demographic.country.response_yemen',
    'app.demographic.country.response_zambia',
    'app.demographic.country.response_zimbabwe',
    'app.demographic.level_of_fluency.i_do_not_speak',
    'app.demographic.level_of_fluency.minimally_fluent',
    'app.demographic.level_of_fluency.somewhat_fluent',
    'app.demographic.level_of_fluency.moderately_fluent',
    'app.demographic.level_of_fluency.mostly_fluent',
    'app.demographic.level_of_fluency.fluent',
    'app.demographic.level_of_interest.not_applicable',
    'app.demographic.level_of_interest.extremely_low',
    'app.demographic.level_of_interest.very_low',
    'app.demographic.level_of_interest.low',
    'app.demographic.level_of_interest.neutral',
    'app.demographic.level_of_interest.high',
    'app.demographic.level_of_interest.very_high',
    'app.demographic.level_of_interest.extremely_high',
    'app.demographic_slider_default_option',
    'app.demographic.formal_education.some_high_school_or_less',
    'app.demographic.formal_education.high_school_graduate',
    'app.demographic.formal_education.some_college',
    'app.demographic.formal_education.college_degree',
    'app.demographic.formal_education.some_graduate_school',
    'app.demographic.formal_education.graduate_degree',
    'app.demographic.formal_education.doctoral_degree',
    'app.demographic.formal_education.professional_degree',
]);
export type DemographicResponseOptionMessages = { [K in (typeof demographicOptionMessages)[number]]: string };
export const demographicResponseOptionIds: DemographicResponseOptionMessages = {
    ...arrayToObject(demographicOptionMessages)
};

const demographicMessages = [...demographicQuestionsMessages, ...demographicOptionMessages];
export type DemographicPageMessages = { [K in (typeof demographicMessages)[number]]: string };
export const demographicMessageIds: DemographicPageMessages = { ...arrayToObject(demographicMessages) };

const listOrganizationsMessages = asLiterals([
    'app.listorganizations.created.on',
    'app.listorganizations.active',
    'app.listorganizations.inactive',
    'app.listorganizations.new.program',
    'app.listorganizations.new.group',
    'app.listorganizations.view',
    'app.listorganizations.report',
    'app.listorganizations.details',
    'app.listorganizations.tooltip.invite_by_qr_code',
    'app.listorganizations.tooltip.sub_program_edit',
    'app.listorganizations.table.beviname',
    'app.listorganizations.table.created_on',
    'app.listorganizations.table.due_date',
    'app.listorganizations.table.completed_percentage',
    'app.listorganizations.table.status',
    'app.listorganizations.table.actions',
    'app.listorganizations.select_organization',
    'app.listorganizations.search',
    'app.listorganizations.new_organization',
    'app.listorganizations.input_program_placeholder',
    'app.listorganizations.input_subprogram_placeholder',
    'app.listorganizations.organization_area',
    'app.listorganizations.download.data_report',
    'app.listorganizations.sort_name_date',
    'app.listorganizations.sort_by',
    'app.sample.data.footer.message_line1',
    'app.sample.data.footer.message_line2',
    'app.sample.data.footer.message_line3',
]);
export type ListOrganizationsMessages = { [K in (typeof listOrganizationsMessages)[number]]: string };
export const listOrganizationsIds: ListOrganizationsMessages = { ...arrayToObject(listOrganizationsMessages) };

const financialReportMessages = asLiterals([
    'app.financialreport.title',
    'app.financialreport.organization_lbl',
    'app.financialreport.start_data_lbl',
    'app.financialreport.end_data_lbl',
    'app.financialreport.created_forms_title',
    'app.financialreport.finished_forms_title',
    'app.financialreport.valid_forms_title',
    'app.financialreport.invalid_forms_title',
    'app.financialreport.unanswered_forms_title',
    'app.financialreport.error_header',
    'app.financialreport.error_text',
]);
export type FinancialReportMessages = { [K in (typeof financialReportMessages)[number]]: string };
export const financialReportIds: FinancialReportMessages = { ...arrayToObject(financialReportMessages) };

const listReportMessages = asLiterals([
    'app.listreport.report_area',
    'app.listreport.new',
    'app.listreport.new_group',
    'app.listreport.add_group',
    'app.listreport.please_add_group_name',
    'app.listreport.search',
    'app.listreport.name',
    'app.listreport.report_type',
    'app.listreport.report_status',
    'app.listreport.report_status_pending_approval',
    'app.listreport.report_status_completed',
    'app.listreport.created_on',
    'app.listreport.confirm_deletion_header',
    'app.listreport.confirm_deletion_body',
    'app.listorganizations.view',
    'app.listorganizations.edit',
    'app.listorganizations.delete',
]);
export type ListReportMessages = { [K in (typeof listReportMessages)[number]]: string };
export const listReportIds: ListReportMessages = { ...arrayToObject(listReportMessages) };

const individualReportDefaultMessages = asLiterals([
    'app.individualreport.you_and_your_worldview',
    'app.individualreport.a_personal_report_from',
    'app.individualreport.bevi_description',
    'app.individualreport.user',
    'app.individualreport.date_of_test',
    'app.individualreport.static_text_1',
    'app.individualreport.static_text_2',
    'app.individualreport.static_text_3',
    'app.individualreport.static_text_4',
    'app.individualreport.static_text_5',
    'app.individualreport.static_text_6',
    'app.individualreport.static_text_7',
    'app.individualreport.static_text_8',
    'app.individualreport.static_text_9_1',
    'app.individualreport.static_text_9_2',
    'app.individualreport.static_text_9_3',
    'app.individualreport.static_text_9_4',
    'app.individualreport.static_text_10_1',
    'app.individualreport.static_text_10_2',
    'app.individualreport.static_text_10_3',
    'app.individualreport.static_text_11',
    'app.individualreport.heading_text_1_1',
    'app.individualreport.heading_text_1_2',
    'app.individualreport.heading_text_1_3',
    'app.individualreport.heading_text_2',
    'app.individualreport.heading_text_3',
    'app.individualreport.heading_text_4',
    'app.individualreport.thankyou_static_1_1',
    'app.individualreport.thankyou_static_1_2',
    'app.individualreport.thankyou_static_2',
    'app.individualreport.thankyou_static_3',
]);

export type IndividualReportDefaultMessages = { [K in (typeof individualReportDefaultMessages)[number]]: string };
export const individualReporDefaultMessagesIds: IndividualReportDefaultMessages = { ...arrayToObject(individualReportDefaultMessages) };

const individualReportDemographicsMessages = asLiterals([
    'app.individualreport.demographics.text'
]);

export type IndividualReportDemographicsMessages = { [K in (typeof individualReportDemographicsMessages)[number]]: string };
export const individualReporDemographicsMessagesIds: IndividualReportDemographicsMessages = { ...arrayToObject(individualReportDemographicsMessages) };

const individualReportTitleMessages = asLiterals([
    'app.individualreport.title.introduction',
    'app.individualreport.title.foundation',
    'app.individualreport.title.tolerance_of_desequilibrium',
    'app.individualreport.title.making_sense',
    'app.individualreport.title.access_to_yourself',
    'app.individualreport.title.access_to_the_thoughts',
    'app.individualreport.title.access_to_the_larger_world',
    'app.individualreport.title.conclusion',
    'app.individualreport.title.thankyou',
    'app.individualreport.title.profile',
]);

export type IndividualReportTitleMessages = { [K in (typeof individualReportTitleMessages)[number]]: string };
export const individualReportTitleMessagesIds: IndividualReportTitleMessages = { ...arrayToObject(individualReportTitleMessages) };

const individualReportFormativeCoreMessages = asLiterals([
    'app.individualreport.formativecore.text'
]);

export type IndividualReportFormativeCoreMessages = { [K in (typeof individualReportFormativeCoreMessages)[number]]: string };
export const individualReportFormativeCoreMessagesIds: IndividualReportFormativeCoreMessages = { ...arrayToObject(individualReportFormativeCoreMessages) };

const individualReportToleranceDisequilibriumMessages = asLiterals([
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_1',
    'app.individualreport.tolerancedisequilibrium.greaterthan69.text2_2',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text2',
    'app.individualreport.tolerancedisequilibrium.lessthan69.text3',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_1',
    'app.individualreport.tolerancedisequilibrium.lessthan30.text2_2',
]);

export type IndividualReportToleranceDisequilibriumMessages = { [K in (typeof individualReportToleranceDisequilibriumMessages)[number]]: string };
export const individualReportToleranceDisequilibriumMessagesIds: IndividualReportToleranceDisequilibriumMessages = { ...arrayToObject(individualReportToleranceDisequilibriumMessages) };

const individualReportWhyWedoMessages = asLiterals([
    'app.individualreport.whywedo.greaterthan69.text',
    'app.individualreport.whywedo.lessthan69.text',
    'app.individualreport.whywedo.lessthan30.text',
]);

export type IndividualReportWhyWeDoMessages = { [K in (typeof individualReportWhyWedoMessages)[number]]: string };
export const individualReportWhyWeDoMessagesIds: IndividualReportWhyWeDoMessages = { ...arrayToObject(individualReportWhyWedoMessages) };

const individualReportAccessSelfMessages = asLiterals([
    'app.individualreport.accessself.textA',
    'app.individualreport.accessself.textB',
    'app.individualreport.accessself.textC',
    'app.individualreport.accessself.textI',
    'app.individualreport.accessself.textH',
    'app.individualreport.accessself.textG',
]);

export type IndividualReportAccessSelfMessages = { [K in (typeof individualReportAccessSelfMessages)[number]]: string };
export const individualReportAccessSelfMessagesIds: IndividualReportAccessSelfMessages = { ...arrayToObject(individualReportAccessSelfMessages) };

const individualReportAccessOthersMessages = asLiterals([
    'app.individualreport.accessothers.textA',
    'app.individualreport.accessothers.textB',
    'app.individualreport.accessothers.textC',
]);

export type IndividualReportAccessOthersMessages = { [K in (typeof individualReportAccessOthersMessages)[number]]: string };
export const individualReportAccessOthersMessagesIds: IndividualReportAccessOthersMessages = { ...arrayToObject(individualReportAccessOthersMessages) };

const individualReportGenderTraditionMessages = asLiterals([
    'app.individualreport.gendertradition.textA',
    'app.individualreport.gendertradition.textB',
    'app.individualreport.gendertradition.textC',
]);

export type IndividualReportGenderTraditionMessages = { [K in (typeof individualReportGenderTraditionMessages)[number]]: string };
export const individualReportGenderTraditionMessagesIds: IndividualReportGenderTraditionMessages = { ...arrayToObject(individualReportGenderTraditionMessages) };

const individualReportVariablesMessages = asLiterals([
    'app.individualreport.variables.textE_1',
    'app.individualreport.variables.textE_2',
    'app.individualreport.variables.textF',
    'app.individualreport.variables.textG_1',
    'app.individualreport.variables.textG_2',
]);

export type IndividualReportVariablesMessages = { [K in (typeof individualReportVariablesMessages)[number]]: string };
export const individualReportVariablesMessagesIds: IndividualReportVariablesMessages = { ...arrayToObject(individualReportVariablesMessages) };

const individualReportEcologicalResonanceMessages = asLiterals([
    'app.individualreport.ecologicalresonance.textA',
    'app.individualreport.ecologicalresonance.textB',
    'app.individualreport.ecologicalresonance.textC',
]);

export type IndividualReportEcologicalResonanceMessages = { [K in (typeof individualReportEcologicalResonanceMessages)[number]]: string };
export const individualReportEcologicalResonanceMessagesIds: IndividualReportEcologicalResonanceMessages = { ...arrayToObject(individualReportEcologicalResonanceMessages) };

const individualReportGlobalEngagementMessages = asLiterals([
    'app.individualreport.globalengagement.textA',
    'app.individualreport.globalengagement.textB',
    'app.individualreport.globalengagement.textC',
]);

export type IndividualReportGlobalEngagementMessages = { [K in (typeof individualReportGlobalEngagementMessages)[number]]: string };
export const individualReportGlobalEngagementMessagesIds: IndividualReportGlobalEngagementMessages = { ...arrayToObject(individualReportGlobalEngagementMessages) };

const individualReportNegativeLifeEventsMessages = asLiterals([
    'app.individualreport.negativelifeevents.low',
    'app.individualreport.negativelifeevents.moderate',
    'app.individualreport.negativelifeevents.high',
]);

export type IndividualReportNegativeEventsMessages = { [K in (typeof individualReportNegativeLifeEventsMessages)[number]]: string };
export const individualReportNegativeLifeEventsMessagesIds: IndividualReportNegativeEventsMessages = { ...arrayToObject(individualReportNegativeLifeEventsMessages) };

const individualReportNeedsClosureMessages = asLiterals([
    'app.individualreport.needsclosure.met_well',
    'app.individualreport.needsclosure.met_at_times',
    'app.individualreport.needsclosure.not_met_well',
]);

export type IndividualReportNeedsClosureMessages = { [K in (typeof individualReportNeedsClosureMessages)[number]]: string };
export const individualReportNeedsClosureMessagesIds: IndividualReportNeedsClosureMessages = { ...arrayToObject(individualReportNeedsClosureMessages) };

const individualReportMessages = [
    ...individualReportDefaultMessages, ...individualReportDemographicsMessages,
    ...individualReportFormativeCoreMessages, ...individualReportToleranceDisequilibriumMessages,
    ...individualReportWhyWedoMessages, ...individualReportTitleMessages,
    ...individualReportAccessSelfMessages, ...individualReportVariablesMessages,
    ...individualReportAccessOthersMessages, ...individualReportGenderTraditionMessages,
    ...individualReportEcologicalResonanceMessages, ...individualReportGlobalEngagementMessages,
    ...individualReportNegativeLifeEventsMessages, ...individualReportNeedsClosureMessages
];
export type IndividualReportMessages = { [K in (typeof individualReportMessages)[number]]: string };
export const individualReportMessagesIds: IndividualReportMessages = { ...arrayToObject(individualReportMessages) };

const internalTemplateMessages = asLiterals([
    'app.internaltemplate.welcome',
    'app.internaltemplate.menu.dashboard',
    'app.internaltemplate.menu.users',
    'app.internaltemplate.menu.organization',
    'app.internaltemplate.menu.institutions',
    'app.internaltemplate.menu.report',
    'app.internaltemplate.menu.sample_report',
    'app.internaltemplate.menu.permission_profile',
    'app.internaltemplate.menu.financial_report',
    'app.internaltemplate.menu.logout',
    'app.internaltemplate.menu.virtualAccount',
    'app.internaltemplate.menu.configureTwoFactorAuthentication',
    'app.internaltemplate.system.maintenance_time',
    'app.internaltemplate.menu.report_permission',
]);

export type InternalTemplateMessages = { [K in (typeof internalTemplateMessages)[number]]: string };
export const internalTemplateMessagesIds: InternalTemplateMessages = { ...arrayToObject(internalTemplateMessages) };

const usersMessages = asLiterals([
    'app.users.listuser.title',
    'app.users.name',
    'app.users.login',
    'app.users.status.inactive',
    'app.users.status.active',
    'app.users.status',
    'app.create_batch_user.excel.email_column',
    'app.create_batch_user.excel.line_number_column',
    'app.create_batch_user.excel.user_error_column',
    'app.create_batch_user.excel.download_error_details',
    'app.create_batch_user.error_already_exists',
    'app.create_batch_user.error_invalid_line',
    'app.create_batch_user.error_wrong_email',
    'app.create_batch_user.error_missing_name',
    'app.create_batch_user.error_details_filename',
    'app.user.organization',
    'app.user.select_organization',
    'app.user.program',
    'app.user.select_program',
    'app.users.listuser.action',
    'app.users.listuser.details',
    'app.users.listuser.reset_password',
    'app.users.listuser.programs_action',
    'app.users.add_new_user',
    'app.user_programs.programName',
    'app.user_programs.subprogramName',
    'app.user_programs.status',
    'app.user_programs.completedOn',
    'app.user_programs.invalid_reason',
    'app.listorganizations.input_username_placeholder',
    'app.listorganizations.input_useremail_placeholder',
    'app.create.batch_user_error',
    'app.create.user.title',
    'app.create.user.single.user',
    'app.create.user.multiple.users',
    'app.create.user.language_codes',
    'app.create.user.choose.option.new.user',
    'app.create.user.download.template.file',
    'app.create.user.download.data.example',
    'app.create.user.upload.file',
    'app.create.user.drag.n.drop.text',
    'app.create.user.dran.n.drop.available.files',
    'app.create.user.remove',
    'app.create.user.selected.file',
    'app.create.user.create.multiple.user.title',
    'app.create.user.create.single.user.title',
    'app.create.user.table.column_actions',
    'app.create.user.edit.single.user.title',
    'app.create.user.is.anonymous',
    'app.create.user.user.type',
    'app.create.user.language',
    'app.create.user.send_welcome_email',
    'app.create.user.capability_visualize_user',
    'app.create.user.capability_add_user',
    'app.create.user.capability_reset_password_user',
    'app.create.user.capability_manage_user',
    'app.create.user.capability_visualize_user_programs',
    'app.create.user.capability_visualize_program_and_experience',
    'app.create.user.capability_add_program_and_experience',
    'app.create.user.capability_rename_program_and_experience',
    'app.create.user.capability_add_subprogram',
    'app.create.user.capability_edit_subprogram',
    'app.create.user.capability_manage_subprogram',
    'app.create.user.capability_show_qr_code_invite_new_users_subprogram',
    'app.create.user.capability_visualize_report',
    'app.create.user.capability_add_report',
    'app.user.profile.participant',
    'app.user.profile.programAdmin',
    'app.user.profile.institutional',
    'app.user.profile.accountManager',
    'app.user.profile.superAdmin',
    'app.user.language.message',
    'app.user.successfulMessage',
    'app.user.updateSuccessfulMessage',
    'app.edit.user.confirmation_title_fail',
    'app.edit.user.confirmation_body_fail',
    'app.edit.user.confirmation_body_fail_already_exists',
    'app.edit.user.confirmation_body_fail_wrong_email',
    'app.edit.user.confirmation_title_success',
    'app.edit.user.confirmation_body_success',
    'app.create.user.confirmation_title_fail',
    'app.create.user.confirmation_body_fail',
    'app.create.user.confirmation_body_fail_already_exists',
    'app.create.user.confirmation_body_fail_wrong_email',
    'app.create.user.confirmation_title_success',
    'app.create.user.confirmation_body_success',
    'app.reset_password.title',
    'app.reset_password.body',
    'app.reset_password.successful_message',
    'app.reset_password.error_message',
    'app.reset_password.new_temporary_password',
    'app.reset_password.new_password',
    'app.reset_password.button_reset',
    'app.users_type.select',
    'app.users_type.all',
    'app.users_type.participant',
    'app.users_type.program_admin',
    'app.users_type.institutional',
    'app.users_type.account_manager',
    'app.users_type.super_admin',
    'app.users_type.external_partner',
    'app.users.listuser.clear_2fa',
    'app.users.confirm_deletion_body'
]);

export type UsersMessages = { [K in (typeof usersMessages)[number]]: string };
export const usersMessagesIds: UsersMessages = { ...arrayToObject(usersMessages) };

const dataDownloadMessages = asLiterals([
    'app.datadownload.checkbox.study_abroad',
    'app.datadownload.checkbox.additional_demographics_info',
    'app.datadownload.checkbox.remove_anonymous_user',
]);

export type DataDownloadMessages = { [K in (typeof dataDownloadMessages)[number]]: string };
export const dataDownloadMessagesIds: DataDownloadMessages = { ...arrayToObject(dataDownloadMessages) };

const noInfoMessages = asLiterals([
    'app.no.info.title.not_found',
    'app.no.info.subtitle.not_found',
    'app.no.info.body.not_found',
    'app.no.info.title.no_data',
    'app.no.info.subtitle.no_data',
    'app.no.info.body.no_data',
    'app.no.info.title.not_authorized',
    'app.no.info.subtitle.not_authorized',
    'app.no.info.body.not_authorized',
    'app.no.info.title.maintenance',
    'app.no.info.subtitle.maintenance',
    'app.no.info.body.maintenance',
]);

export type NoInfoMessages = { [K in (typeof noInfoMessages)[number]]: string };
export const noInfoMessagesIds: NoInfoMessages = { ...arrayToObject(noInfoMessages) };

const createReportMessages = asLiterals([
    'app.create_report.btn_fake_name_all',
    'app.create_report.btn_real_name_all',
    'app.create_report.btn_fake_name',
    'app.create_report.btn_real_name',
    'app.create_report.title',
    'app.create_report.summary_line1',
    'app.create_report.summary_line2',
    'app.create_report.step1',
    'app.create_report.step2',
    'app.create_report.step3',
    'app.create_report.step3Individual',
    'app.create_report.step4',
    'app.create_report.step3_username_column',
    'app.create_report.step3_aliasname_column',
    'app.create_report.step3_action_column',
    'app.create_report.step3_no_user_title',
    'app.create_report.step3_no_user_subtitle',
    'app.create_report.step3_user_name_column_title',
    'app.create_report.step3_user_exporting_column_title',
    'app.create_report.name',
    'app.create_report.requester',
    'app.create_report.report_type',
    'app.create_report.select_subprogram',
    'app.create_report.select_subprogram_warning',
    'app.create_report.group_report',
    'app.create_report.longitudinal_report',
    'app.create_report.comparative_by_group_report',
    'app.create_report.comparative_by_individual_report',
    'app.create_report.select_organization',
    'app.create_report.select_program',
    'app.create_report.select_sub_program',
    'app.create_report.organization_name',
    'app.create_report.program_name',
    'app.create_report.sub_program_name',
    'app.create_report.actions',
    'app.create_report.action_remove',
    'app.create_report.show_filters',
    'app.create_report.hide_filters',
    'app.create_report.filter_select_default_option',
    'app.create_report.filter_select_with_values',
    'app.create_report.filter_age',
    'app.create_report.filter_gender',
    'app.create_report.filter_years_of_education',
    'app.create_report.filter_primary_country_of_origin',
    'app.create_report.filter_marital_status',
    'app.create_report.filter_political_orientation',
    'app.create_report.filter_ethnic_background',
    'app.create_report.filter_religious_orientation',
    'app.create_report.filter_religious_commitment',
    'app.create_report.filter_academic_rank',
    'app.create_report.filter_time_spent_in_other_countries',
    'app.create_report.filter_nro_visited_countries',
    'app.create_report.filter_fluency',
    'app.create_report.filter_years_of_language_study',
    'app.create_report.filter_mother_country',
    'app.create_report.filter_father_country',
    'app.create_report.filter_mother_education',
    'app.create_report.filter_father_education',
    'app.create_report.filter_minority_group',
    'app.create_report.filter_personal_interest',
    'app.create_report.filter_study_abroad_satisfaction',
    'app.create_report.filter_primary_area_of_work',
    'app.create_report.filter_area_of_occupations',
    'app.create_report.filter_income_level_of_your_home_country',
    'app.create_report.btn_search',
    'app.create_report.btn_save',
    'app.create_report.placeholder.name',
    'app.create_report.select.report_type',
    'app.create_report.select_countries_options_lbl',
    'app.create_report.selected_countries_lbl',
    'app.create_report.select_ethnics_options_lbl',
    'app.create_report.selected_ethnics_lbl',
    'app.create_report.select_number_of_forms_lbl',
    'app.create_report.select_relationship_status_options_lbl',
    'app.create_report.selected_relationship_status_lbl',
    'app.create_report.select_religion_options_lbl',
    'app.create_report.selected_religion_lbl',
    'app.create_report.select_primary_area_work_options_lbl',
    'app.create_report.selected_primary_area_work_lbl',
    'app.create_report.individual_report',
    'app.create_report.longitudinal_individual_report',
    'app.create_report.combined_report',
    'app.create_report.partner_change_report',
    'app.create_report.individual',
    'app.create_report.partner',
    'app.create_report.group',
    'app.create_report.select.group',
    'app.create_report.partner_change_report',
    'app.create_report.email_in_use',
    'app.create_report.add_new_participant',
]);
export type CreateReportMessages = { [K in (typeof createReportMessages)[number]]: string };
export const createReportMessagesIds: CreateReportMessages = { ...arrayToObject(createReportMessages) };

const reportsMessages = asLiterals([
    'app.reports.insuficient_data',
    'app.reports.decile',
    'app.reports.group_report.title',
    'app.reports.longitudinal.title',
    'app.reports.comparative_by_group.title',
    'app.reports.comparative_by_individual.title',
    'app.report.menu.introduction',
    'app.report.menu.basic_descriptive_statistics',
    'app.report.menu.aggregate_profile',
    'app.report.menu.decile_profile',
    'app.report.menu.aggregate_by_demographics',
    'app.report.menu.background_domain_contrast',
    'app.report.menu.profile_background_contrast',
    'app.report.menu.profile_contrast',
    'app.report.menu.experiential_reflection_items',
    'app.report.menu.country_of_origin',
    'app.report.menu.gender',
    'app.report.menu.education',
    'app.report.menu.ethnicity',
    'app.report.menu.income',
    'app.report.menu.interest',
    'app.report.menu.politics',
    'app.report.menu.religion',
    'app.report.menu.satisfaction',
    'app.report.menu.question_1',
    'app.report.menu.question_2',
    'app.report.menu.question_3',
    'app.report.menu.narrative_report',
    'app.report.introduction.number_of_participants',
    'app.report.introduction.create_date',
    'app.report.introduction.created_by',
    'app.report.introduction.visualized_by',
    'app.report.introduction.group_name',
    'app.report.introduction.program_name',
    'app.report.scales.consistency',
    'app.report.scales.congruency',
    'app.report.scales.negative_life_events',
    'app.report.scales.needs_closure',
    'app.report.scales.needs_fulfillment',
    'app.report.scales.identity_diffusion',
    'app.report.scales.basic_openness',
    'app.report.scales.self_certitude',
    'app.report.scales.basic_determinism',
    'app.report.scales.socioemotional_convergence',
    'app.report.scales.physical_resonance',
    'app.report.scales.emotional_attunement',
    'app.report.scales.self_awareness',
    'app.report.scales.meaning_quest',
    'app.report.scales.religious_traditionalism',
    'app.report.scales.gender_traditionalism',
    'app.report.scales.sociocultural_openness',
    'app.report.scales.ecological_resonance',
    'app.report.scales.global_resonance',
    'app.report.aggregate_profile.high',
    'app.report.aggregate_profile.low',
    'app.report.aggregate_profile.average',
    'app.report.background_statistic_item.income_participants',
    'app.report.background_statistic_item.lowest',
    'app.report.background_statistic_item.middle',
    'app.report.background_statistic_item.highest',
    'app.report.background_statistic_item.background_information',
    'app.report.background_statistic_item.age',
    'app.report.background_statistic_item.average_age',
    'app.report.background_statistic_item.answer',
    'app.report.background_statistic_item.not_answer',
    'app.report.background_statistic_item.range',
    'app.report.background_statistic_item.gender',
    'app.report.background_statistic_item.ethnic_background',
    'app.report.background_statistic_item.country_of_origin',
    'app.report.background_statistic_item.raised_in',
    'app.report.background_statistic_item.details',
    'app.report.background_statistic_item.not_raised_in',
    'app.report.background_statistic_item.years_of_education',
    'app.report.background_statistic_item.income',
    'app.report.background_statistic_item.average',
    'app.report.background_statistic_item.marital_status',
    'app.report.background_statistic_item.religion',
    'app.report.background_statistic_item.interest',
    'app.report.background_statistic_item.satisfaction',
    'app.report.background_statistic_item.domain_scores',
    'app.report.background_statistic_item.validity',
    'app.report.background_statistic_item.average_missing_items',
    'app.report.background_statistic_item.average_quantitative_completion_time',
    'app.report.background_statistic_item.full_scale',
    'app.report.background_statistic_item.formative_variables',
    'app.report.background_statistic_item.fulfillment_of_core_needs',
    'app.report.background_statistic_item.tolerance_of_disequilibrium',
    'app.report.background_statistic_item.critical_thinking',
    'app.report.background_statistic_item.self_access',
    'app.report.background_statistic_item.other_access',
    'app.report.background_statistic_item.global_access',
    'app.report.decile_profile.high',
    'app.report.decile_profile.low',
    'app.report.decile_profile.average',
    'app.report.background_domain_contrast.title',
    'app.report.background_domain_contrast.lowest_full_scale',
    'app.report.background_domain_contrast.middle_full_scale',
    'app.report.background_domain_contrast.highest_full_scale',
    'app.report.background_domain_contrast.mean',
    'app.report.background_domain_contrast.range',
    'app.report.background_domain_contrast.background_information',
    'app.report.profile_contrast.lowest',
    'app.report.profile_contrast.middle',
    'app.report.profile_contrast.highest',
    'app.report.profile_contrast.lowest_optimal_profiles',
    'app.report.profile_contrast.middle_optimal_profiles',
    'app.report.profile_contrast.highest_optimal_profiles',
    'app.report.aggregate_profile.religion.selected_religion',
    'app.report.aggregate_profile.country_of_origin.selected_country',
    'app.report.aggregate_profile.country_of_origin.selected_countries',
    'app.report.aggregate_profile.country_of_origin.other',
    'app.report.aggregate_profile.religious_orientation.selected_ethnic',
    'app.report.aggregate_profile.religious_orientation.selected_ethnics',
    'app.report.aggregate_profile.religious_orientation.other',
    'app.report.aggregate_profile.parents_country.same',
    'app.report.aggregate_profile.parents_country.different',
    'app.report.aggregate_profile.relationship_status.selected_relationship_status',
    'app.report.aggregate_profile.relationship_status.other',
    'app.report.aggregate_profile.gender.male',
    'app.report.aggregate_profile.gender.female',
    'app.report.aggregate_profile.gender.self_identify',
    'app.report.aggregate_profile.education.lowest_30',
    'app.report.aggregate_profile.education.highest_30',
    'app.report.aggregate_profile.ethnicity.selected_ethnicity',
    'app.report.aggregate_profile.ethnicity.selected_ethnicities',
    'app.report.aggregate_profile.ethnicity.other',
    'app.report.aggregate_profile.income.bottom_third',
    'app.report.aggregate_profile.income.middle_third',
    'app.report.aggregate_profile.income.top_third',
    'app.report.aggregate_profile.interest.low',
    'app.report.aggregate_profile.interest.middle',
    'app.report.aggregate_profile.interest.high',
    'app.report.aggregate_profile.politics.low',
    'app.report.aggregate_profile.politics.middle',
    'app.report.aggregate_profile.politics.high',
    'app.report.aggregate_profile.age.low',
    'app.report.aggregate_profile.age.middle',
    'app.report.aggregate_profile.age.high',
    'app.report.aggregate_profile.religion.low',
    'app.report.aggregate_profile.religion.middle',
    'app.report.aggregate_profile.religion.high',
    'app.report.aggregate_profile.satisfaction.low',
    'app.report.aggregate_profile.satisfaction.middle',
    'app.report.aggregate_profile.satisfaction.high',
    'app.report.aggregate_profile.mother_father_education.low',
    'app.report.aggregate_profile.mother_father_education.middle',
    'app.report.aggregate_profile.mother_father_education.high',
    'app.report.aggregate_profile.time_spent_other_countries.low',
    'app.report.aggregate_profile.time_spent_other_countries.middle',
    'app.report.aggregate_profile.time_spent_other_countries.high',
    'app.report.aggregate_profile.minority_group.minority',
    'app.report.aggregate_profile.minority_group.majority',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_area_of_work',
    'app.report.aggregate_profile.primary_area_of_work.selected_primary_areas_of_work',
    'app.report.aggregate_profile.primary_area_of_work.other',
    'app.report.aggregate_profile.years_of_language_study.low',
    'app.report.aggregate_profile.years_of_language_study.middle',
    'app.report.aggregate_profile.years_of_language_study.high',
    'app.report.aggregate_profile.academic_rank.low',
    'app.report.aggregate_profile.academic_rank.middle',
    'app.report.aggregate_profile.academic_rank.high',
    'app.report.aggregate_profile.visited_countries.low',
    'app.report.aggregate_profile.visited_countries.middle',
    'app.report.aggregate_profile.visited_countries.high',
    'app.report.aggregate_profile.fluency.low',
    'app.report.aggregate_profile.fluency.middle',
    'app.report.aggregate_profile.fluency.high',
    'app.report.aggregate_demographic.core_demographics',
    'app.report.aggregate_demographic.parental_background',
    'app.report.aggregate_demographic.political_religious_inclinations',
    'app.report.aggregate_demographic.education_background',
    'app.report.aggregate_demographic.international_cultural_experiences',
    'app.report.aggregate_demographic.age',
    'app.report.aggregate_demographic.gender',
    'app.report.aggregate_demographic.relationship_status',
    'app.report.aggregate_demographic.country_origin',
    'app.report.aggregate_demographic.parent_country',
    'app.report.aggregate_demographic.mother_education',
    'app.report.aggregate_demographic.father_education',
    'app.report.aggregate_demographic.ethnic_background',
    'app.report.aggregate_demographic.minority_group',
    'app.report.aggregate_demographic.political_orientation',
    'app.report.aggregate_demographic.religious_orientation',
    'app.report.aggregate_demographic.religious_commitment',
    'app.report.aggregate_demographic.income_level',
    'app.report.aggregate_demographic.years_education',
    'app.report.aggregate_demographic.primary_area_work',
    'app.report.aggregate_demographic.academic_rank',
    'app.report.aggregate_demographic.time_spent_countries',
    'app.report.aggregate_demographic.visited_countries',
    'app.report.aggregate_demographic.personal_interest',
    'app.report.aggregate_demographic.study_abroad_satisfaction',
    'app.report.aggregate_demographic.fluency',
    'app.report.aggregate_demographic.years_language_study',
    'app.report.comparative_individual_report_result_title',
    'app.report.partner_report_result_title',
    'app.report.partner_change_report_result_title',
    'app.report.comparative_individual_report_result_description',
    'app.report.comparative_individual_report_result_description_no_change',
    'app.report.permission.modal_title',
    'app.report.permission.modal_description',
    'app.report.permission.pending',
    'app.report.permission.approved',
    'app.report.permission.rejected',
    'app.report.permission.approve',
    'app.report.permission.reject',
    'app.reports.selectAll',
    'app.reports.deselectAll',
]);

export type ReportsMessages = { [K in (typeof reportsMessages)[number]]: string };
export const reportsMessagesIds: ReportsMessages = { ...arrayToObject(reportsMessages) };

const timeMessages = asLiterals([
    'app.time.day',
    'app.time.days',
    'app.time.hour',
    'app.time.hours',
    'app.time.minute',
    'app.time.minutes',
    'app.time.second',
    'app.time.seconds',
    'app.time.and',
]);

export type TimeMessages = { [K in (typeof timeMessages)[number]]: string };
export const timeMessagesIds: TimeMessages = { ...arrayToObject(timeMessages) };

const permissionMessages = asLiterals([
    'app.permission.title',
    'app.permission.remove_permission_to_all',
    'app.permission.give_permission_to_all',
    'app.permission.remove_permission',
    'app.permission.give_permission',
    'app.permission.table_title.permission',
    'app.permission.table_title.name',
    'app.permission.table_title.email',
    'app.permission.button.update',
    'app.permission.message',
    'app.permission.add_permissions_with_excel_file',
    'app.permission.add_permissions',
    'app.permission.permissions',
    'app.permission.download_tooltip'
]);

export type PermissionMessages = { [K in (typeof permissionMessages)[number]]: string };
export const permissionMessagesIds: PermissionMessages = { ...arrayToObject(permissionMessages) };

const institutionsMessages = asLiterals([
    'app.institutions.title',
    'app.institutions.new',
    'app.institutions.search',
    'app.institutions.list.name',
    'app.institutions.list.edit',
    'app.institutions.list.action',
    'app.institutions.add.title',
    'app.institutions.edit.title',
    'app.institutions.add.successful_message',
    'app.institutions.add.error_message',
    'app.institutions.add.new_name',
    'app.institutions.add.button_save',
    'app.institutions.add.hide_demographics_questions',
    'app.institutions.add.demographics_extra_questions',
    'app.institutions.hide_demographics_questions.successful_message',
    'app.institutions.demographics_questions.question',
    'app.institutions.demographics_questions.type',
    'app.institutions.demographics_questions.type_0',
    'app.institutions.demographics_questions.type_1',
    'app.institutions.demographics_questions.type_2',
    'app.institutions.demographics_questions.add.title',
    'app.institutions.demographics_questions.edit.title',
    'app.institutions.demographics_questions.title',
    'app.institutions.demographics_questions.options',
    'app.institutions.demographics_questions.description',
    'app.institutions.demographics_questions.delete',
    'app.institutions.chat.successful_message',
    'app.institutions.chat.add_chat_all',
    'app.institutions.chat.question_add_chat_all'
]);
export type InstitutionMessages = { [K in (typeof institutionsMessages)[number]]: string };
export const institutionIds: InstitutionMessages = { ...arrayToObject(institutionsMessages) };

const programsMessages = asLiterals([
    'app.programs.edit.title',
    'app.programs.rename',
    'app.programs.add.title',
    'app.programs.add.successful_message',
    'app.programs.add.error_message',
    'app.programs.add.new_name',
    'app.programs.add.button_save',
    'app.programs.chat.successful_message',
    'app.programs.chat.add_chat_all',
    'app.programs.chat.question_add_chat_all'

]);
export type ProgramsMessages = { [K in (typeof programsMessages)[number]]: string };
export const programsIds: ProgramsMessages = { ...arrayToObject(programsMessages) };

const virtualAccountMessages = asLiterals([
    'app.virtual_account.title',
    'app.virtual_account.add_interactions',
    'app.virtual_account.add_interactions_to_organization',
    'app.virtual_account.interactions_placeholder',
    'app.virtual_account.successful_message',
    'app.virtual_account.day',
    'app.virtual_account.interaction_credit',
    'app.virtual_account.interaction_debit',
    'app.virtual_account.balance',
    'app.virtual_account.total_balance_month',
    'app.virtual_account.interactions_available'
]);

export type VirtualAccountMessages = { [K in (typeof virtualAccountMessages)[number]]: string };
export const virtualAccountIds: VirtualAccountMessages = { ...arrayToObject(virtualAccountMessages) };